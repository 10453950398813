<template>
  
    <form role="form" @submit="onFormSubmit" name="create-category">
        <div class="form-group">
            <label for="createTitle" class="sr-only">{{$t('shop.info.category.title')}}</label>
            <input id="createTitle" type="text" :placeholder="$t('shop.info.category.title_placeholder')" class="form-control" v-model="categoryForm.title" required />
        </div>
        <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">Create</button>
    </form>

</template>


<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import * as Ladda from 'ladda';

import { useCategoryAdmin } from '../../../../composables/useCategoryAdmin';

export default defineComponent({
    props: {
      mode: {
        type: String as PropType<"PRODUCT"|"ACTIVITY">,
        default: "PRODUCT"
      }
    },
    components: {
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { categoryForm, createCategory } = useCategoryAdmin(props, context);

      var laddaSubmit:Ladda.LaddaButton|null = null;

      onMounted(() => {
        var button:HTMLButtonElement|null = document.querySelector( 'form[name=create-category] button' );
        laddaSubmit = Ladda.create(button!);
      })

      const onFormSubmit = (evt:Event) => {
        // We prevent submit of the page
        evt.preventDefault();

        laddaSubmit!.start();
        
        createCategory().then((result:any) => {
          if(result.created) {  
            context.emit('category-created', result.category);
          }
          laddaSubmit!.stop();
        });
      }
      return { 
        categoryForm,
        onFormSubmit
      }
    }
})
</script>