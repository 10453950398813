import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { types as applicationTypes } from '@root/src/client/store/application';
import { useShopAdmin } from "./useShopAdmin";
import { useCategoryAdmin } from "./useCategoryAdmin";

interface ProductAdminInput {
  mode?: "PRODUCT" | "ACTIVITY"
}

export function useProductAdmin(props:ProductAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();
  
  // We set the mode for shop products
  var mode = props.mode;
  if(mode == undefined) {
    mode = "PRODUCT";
  }

  const { selectedShop, callShopAdmin } = useShopAdmin(props, {emit})
  const { categories, getCategoryFromID } = useCategoryAdmin(props, {emit})

  var isListLoading:Ref<boolean> = ref(false);

  const isProductUpdateAllowed:Ref<boolean> = ref(false);
  const isProductCreateAllowed:Ref<boolean> = ref(false);
  const isProductRemoveAllowed:Ref<boolean> = ref(false);
  const isProductPublishAllowed:Ref<boolean> = ref(false);

  const allowedProductTypes = computed(() => {
    if(mode == "PRODUCT") {
      return selectedShop.value.shop.allowedProductTypes ? selectedShop.value.shop.allowedProductTypes : ['PRODUCT']
    }
    else {
      return selectedShop.value.shop.allowedActivityTypes ? selectedShop.value.shop.allowedActivityTypes : ['ACTIVITY']
    }
  })

  const updateListProducts = async () => {

    isListLoading.value = true;
    try {
      var input = {
        mode : mode
      }
      var response = await callShopAdmin('/shop/'+selectedShop.value.shop._id+'/products', input);
      var mutation = mode == "PRODUCT" ? applicationTypes.mutations.SET_USER_PRODUCTS : applicationTypes.mutations.SET_USER_ACTIVITIES;
      store.commit('application/'+mutation, response.products ? response.products : []);
      if(response.options) {
        isProductUpdateAllowed.value = response.options.isUpdateAllowed;
        isProductCreateAllowed.value = response.options.isCreateAllowed;
        isProductRemoveAllowed.value = response.options.isRemoveAllowed;
        isProductPublishAllowed.value = response.options.isPublishAllowed;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }
  updateListProducts();

  const products = computed(() => {
    var getter = mode == "PRODUCT" ? applicationTypes.getters.GET_USER_PRODUCTS : applicationTypes.getters.GET_USER_ACTIVITIES;
    return store.getters['application/'+getter];
  })


  // We check when there is a new shop selected
  watch(
    () => selectedShop.value.shop._id,
    (val:any, oldVal:any) => {
      // We update the categories when the selected shop is updated
      updateListProducts();
    },
    { deep: false }
  )

  const productForm:any = reactive({
    title: '',
    type: allowedProductTypes.value.length == 1 ? allowedProductTypes.value[0] : '',
    subtitle: '',
    reference: '',
    isActivated: true,
    description: '',
    category: null,
    price: {
      amount: '',
      nonDiscountedAmount: '',
      currencyCode: 'EUR'
    },
    pictures: [],
    relatedProductsIDs:[]
  });

  const updateProductFormForUpdate = (product:any) => {
    productForm.productID = (product && product._id) ? product._id : undefined,
    productForm.title =  product.title
    productForm.type =  product.type
    productForm.subtitle =  product.subtitle
    productForm.reference =  product.reference
    productForm.isActivated =  product.isActivated
    productForm.description =  product.description
    productForm.category = null
    productForm.methodsOfDeliveryCodes = product.methodsOfDeliveryCodes
    productForm.pictures = [],
    productForm.relatedProductsIDs =[]

    if(mode == "ACTIVITY") {
      if(products.value.length > 0 && product.relatedActivities_ids) {
        productForm.relatedProductsIDs = product.relatedActivities_ids;
      }
      productForm.price = {
        amount: product.pricePerPerson.amount,
        nonDiscountedAmount: product.pricePerPerson.nonDiscountedAmount,
        currencyCode: product.pricePerPerson.currencyCode
      }
      productForm.duration = product.duration;
      productForm.minNumberOfPersons = product.minNumberOfPersons;
      productForm.maxNumberOfPersons = product.maxNumberOfPersons;
    }
    else if(mode == "PRODUCT") {
      if(products.value.length > 0 && product.relatedProducts_ids) {
        productForm.relatedProductsIDs = product.relatedProducts_ids;
      }
      productForm.price =  {
        amount: product.price.amount,
        nonDiscountedAmount: product.price.nonDiscountedAmount,
        currencyCode: product.price.currencyCode
      }
    }

    if(categories.value.length > 0 && product.category_id) {
      productForm.category = getCategoryFromID(product.category_id);
    }
  }

  const createProduct = async (extraForm?:any) => {
    var result:any = {
      created: false
    }

    var input = {
      "shopID" : selectedShop.value.shop._id,
      "mode" : mode,
      ...productForm,
      ...(extraForm ? extraForm : {}),
      category: undefined,
      categoryID: productForm.category ? productForm.category!._id : undefined
    }

    var formData = new FormData();
    if(productForm.pictures && productForm.pictures.length > 0) {
      for(var picture of productForm.pictures) {
        formData.append("pictures", picture!, (picture! as File).name);  
      }
    }

    try {
      var response = await callShopAdmin('/product/create', input, formData);
      if(response.created) {  
        // We update the list
        updateListProducts();

        result.created = true;
        result.product = response.product;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const updateProduct = async (extraForm?:any) => {

    var result:any = {
      updated: false
    }

    var input:any = {
      "mode" : mode,
      ...productForm,
      ...(extraForm ? extraForm : {}),
      category: undefined,
      categoryID: productForm.category ? productForm.category!._id : undefined
    }

    try {
      var response = await callShopAdmin('/product/'+productForm.productID+'/update', input);
      if(response.updated) {  
        // We update the list
        updateListProducts();

        result.updated = true;
        result.product = response.product;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const removeProduct = async (productID:string) => {
    isListLoading.value = true;
    try {
      var input:any = {
        "mode" : mode
      }
      var response = await callShopAdmin('/product/'+productID+'/remove', input);
      if(response.removed) {
        updateListProducts();
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return true;
  }

  const uploadPicture = async (productID:string, picture:File):Promise<{uploaded:boolean, picture?:any}> => {

    var input = {
      "mode" : mode
    };
    var formData = new FormData();
    if(picture != null) {
      formData.append("upload", picture, picture.name);  
    }

    var result:{uploaded:boolean, picture?:any} = {
      uploaded:false
    }

    try {
      var response = await callShopAdmin('/product/'+productID+'/upload-picture', input, formData);
      if(response.uploaded) {
        // We update the list
        updateListProducts();

        result.uploaded = true;
        result.picture = response.picture;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const removePicture = async (productID:string, pictureID:string) => {

    var input = {
      "pictureID": pictureID,
      "mode" : mode
    };

    var result:any = {
      removed:false
    }

    try {
      var response = await callShopAdmin('/product/'+productID+'/remove-picture', input);
      if(response.removed) {
        // We update the list
        updateListProducts();
        
        result.removed = true;
        result.pictures = response.pictures;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const updateRanking = async(productID:string, direction:string) => {

    var input = {
      "direction" : direction,
      "mode" : mode
    }

    isListLoading.value = true;
    try {
      var response = await callShopAdmin('/product/'+productID+'/update-ranking', input);
      if(response.updated) {
        await updateListProducts();
      }
      isListLoading.value = false;
      return response;
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return false;
  }

  return {
    isListLoading,
    products,
    productForm,
    updateProductFormForUpdate,
    updateProduct,
    createProduct,
    removeProduct,
    uploadPicture,
    removePicture,
    updateRanking,
    allowedProductTypes,
    isProductUpdateAllowed,
    isProductCreateAllowed,
    isProductRemoveAllowed,
    isProductPublishAllowed
  }
  
}