var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('TinyMceEditor',{ref:"tinyMceEditor",class:{..._vm.inputCSSClass, 'required':_vm.required, ..._vm.classes},attrs:{"id":_vm.id,"required":_vm.required,"init":{
    plugins : 'advlist lists link image table code help wordcount',
    toolbar: 'bullist numlist code',
    //toolbar : 'code',
    menubar : false,
    language: 'fr_FR',
    element_format: 'xhtml',
    extended_valid_elements: 'i[class],em,b,strong',
    content_style: _vm.options.style
  },"api-key":"hfucujcm3bwbw8lhf642agjt6lo0nkzii3xv13pmg8sx0pjn"},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}})
}
var staticRenderFns = []

export { render, staticRenderFns }