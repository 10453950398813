import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';

import { CustomHtmlDataProcessor } from './CustomHtmlDataProcessor';

export class CustomEditor extends ClassicEditor {
	constructor( element:any, config:any ) {
		// @ts-ignore
		super( element, config );
		// @ts-ignore
        this.data.processor = new CustomHtmlDataProcessor(this.data.viewDocument, config.customHtmlDataProcessor);
		
	}
}