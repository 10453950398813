import { Plugin } from '@ckeditor/ckeditor5-core';
import { logWarning } from '@ckeditor/ckeditor5-utils';
import { FileRepository } from '@ckeditor/ckeditor5-upload';

import { UploadPictureResult } from '../../components/panels/input/content/interfaces';
import Vue from '@fwk-node-modules/vue'
import * as api from '@fwk-client/utils/api';
import { AxiosRequestConfig } from 'axios';

export interface CkEditorUploadAdapterOptions {
	uploadHandler:(picture:File, apiOptions?:api.ApiOptions) => Promise<{uploaded:boolean, picture?:UploadPictureResult}>,
	app:Vue
}

/**
 * 
 *
 * @extends module:core/plugin~Plugin
 */
export class CkEditorUploadAdapter extends Plugin {
	/**
	 * @inheritDoc
	 */
	static get requires() {
		return [ FileRepository ];
	}

	/**
	 * @inheritDoc
	 */
	static get pluginName() {
		return 'CkEditorUploadAdapter';
	}

	/**
	 * @inheritDoc
	 */
	init() {
		// We take config directly from private attribute as standard method is doing a clone which does not work with Vue reference
		// @ts-ignore
		const options:CkEditorUploadAdapterOptions = this.editor.config._config.ckEditorUploadAdapter;

		if ( !options ) {
			return;
		}

		if ( !options.uploadHandler ) {
			
			logWarning( 'simple-upload-adapter-missing-uploadurl' );

			return;
		}

		// @ts-ignore
		(this.editor.plugins.get( FileRepository ) as any).createUploadAdapter = (loader:any) => {
			return new Adapter( loader, options );
		};
	}
}

/**
 * Upload adapter.
 *
 * @private
 */
class Adapter {

    private loader:any;
    private options:CkEditorUploadAdapterOptions;
    private controller?:AbortController;

	/**
	 * Creates a new adapter instance.
	 *
	 * @param {module:upload/filerepository~FileLoader} loader
	 * @param {module:upload/adapters/simpleuploadadapter~SimpleUploadConfig} options
	 */
	constructor( loader:any, options:CkEditorUploadAdapterOptions ) {
		/**
		 * FileLoader instance to use during the upload.
		 *
		 * @member {module:upload/filerepository~FileLoader} #loader
		 */
		this.loader = loader;

		/**
		 * The configuration of the adapter.
		 *
		 * @member {module:upload/adapters/simpleuploadadapter~SimpleUploadConfig} #options
		 */
		this.options = options;
	}

	/**
	 * Starts the upload process.
	 *
	 * @see module:upload/filerepository~UploadAdapter#upload
	 * @returns {Promise}
	 */
	upload() {
		return this.loader.file
			.then( (file:File) => {
				this.controller = new AbortController();
				var axiosOptions:AxiosRequestConfig = {
					signal: this.controller.signal,
					onUploadProgress : (progressEvent) => {
						const { loaded, total } = progressEvent
						if ( loaded != undefined && total != undefined ) {
							this.loader.uploadTotal = total;
							this.loader.uploaded = loaded;
						}
					},
				}
				return this.options.uploadHandler.call(undefined, file, axiosOptions).then((result) => {
					if(result.uploaded && result.picture) {
						var picture = result.picture;

						var url = picture.original.publicUrl;
						if(picture.original.path) {
							url = this.options.app.getStaticURLFromPath(picture.original.path);
						}
						const urls = { default: url };

						var uploadResult = {
							...result,
							urls
						}

						// Resolve with the normalized `urls` property and pass the rest of the response
						// to allow customizing the behavior of features relying on the upload adapters.
						return Promise.resolve(uploadResult)
					}
					return Promise.reject();
				  });	
			})
	}

	/**
	 * Aborts the upload process.
	 *
	 * @see module:upload/filerepository~UploadAdapter#abort
	 * @returns {Promise}
	 */
	abort() {
		if ( this.controller ) {
			this.controller.abort();
		}
	}
}