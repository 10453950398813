<template>
  <div class="wine-form">
    <div class="row">
      <div class="col-lg-12">
        <h3>{{$t('shop.products.products.types.WINE')}}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <h4>{{$t('shop.info.product.wine.information')}}</h4>
      </div>
    </div>
    <TextField 
      v-bind:value.sync="form.capacity" 
      id="updateCapacity" 
      :label="$t('shop.info.product.wine.capacity')" 
      :placeholder="$t('shop.info.product.wine.capacity_placeholder')" 
      :labelAsColumn="true"
      :required="false"
    />
    <TextField 
      v-bind:value.sync="form.degree" 
      id="updateDegree" 
      :label="$t('shop.info.product.wine.degree')" 
      :placeholder="$t('shop.info.product.wine.degree_placeholder')" 
      :labelAsColumn="true"
      :required="false"
    />
    <SwitchCheck 
      ref="updateIsBio"
      v-bind:value.sync="form.isBio" 
      id="updateIsBio" 
      :label="$t('shop.info.product.wine.isBio')" 
      :labelAsColumn="true"
      :required="false"
    />
    <div class="row">
      <div class="col-lg-12">
        <h4>{{$t('shop.info.product.wine.elaboration')}}</h4>
      </div>
    </div>
    <LocalizedText 
      v-bind:value.sync="form.elaboration" 
      id="updateElaboration" 
      :label="$t('shop.info.product.wine.elaboration')" 
      :placeholder="$t('shop.info.product.wine.elaboration_placeholder')" 
      :labelAsColumn="true"
      :required="false"
      :languageCodes="availableLanguageCodes"
      :rowClass="{'form-group':true, 'row':true, 'update-elaboration':true}"
    />
    <div class="row">
      <div class="col-lg-12">
        <h4>{{$t('shop.info.product.wine.tasting')}}</h4>
      </div>
    </div>
    <div class="form-group row">
      <label for="updateSweetness" class="col-lg-3 col-form-label">{{$t('shop.info.product.wine.sweetness.title')}}</label>
      <div class="col-lg-9">
        <WineSweetness v-bind:value.sync="form.sweetness"  />
      </div>
    </div>
    <LocalizedText 
      v-bind:value.sync="form.eye" 
      id="updateEye" 
      :label="$t('shop.info.product.wine.eye')" 
      :placeholder="$t('shop.info.product.wine.eye_placeholder')" 
      :labelAsColumn="true"
      :required="false"
      :languageCodes="availableLanguageCodes"
      :rowClass="{'form-group':true, 'row':true, 'update-eye':true}"
    />
    <LocalizedText 
      v-bind:value.sync="form.nose" 
      id="updateNose" 
      :label="$t('shop.info.product.wine.nose')" 
      :placeholder="$t('shop.info.product.wine.nose_placeholder')" 
      :labelAsColumn="true"
      :required="false"
      :languageCodes="availableLanguageCodes"
      :rowClass="{'form-group':true, 'row':true, 'update-nose':true}"
    />
    <LocalizedText 
      v-bind:value.sync="form.mouth" 
      id="updateMouth" 
      :label="$t('shop.info.product.wine.mouth')" 
      :placeholder="$t('shop.info.product.wine.mouth_placeholder')" 
      :labelAsColumn="true"
      :required="false"
      :languageCodes="availableLanguageCodes"
      :rowClass="{'form-group':true, 'row':true, 'update-mouth':true}"
    />
    <LocalizedText 
      v-bind:value.sync="form.pairing" 
      id="updatePairing" 
      :label="$t('shop.info.product.wine.pairing')" 
      :placeholder="$t('shop.info.product.wine.pairing_placeholder')" 
      :labelAsColumn="true"
      :required="false"
      :languageCodes="availableLanguageCodes"
      :rowClass="{'form-group':true, 'row':true, 'update-pairing':true}"
    />
    <div class="form-group row">
      <label for="updateTemperature" class="col-lg-3 col-form-label">{{$t('shop.info.product.wine.temperature')}}</label>
      <div class="col-lg-9">
        <input id="updateTemperatureMin" type="text" :placeholder="$t('shop.info.product.wine.temperature_placeholder')" class="form-control" v-model="form.temperature[0]" />
        <input id="updateTemperatureMax" type="text" :placeholder="$t('shop.info.product.wine.temperature_placeholder')" class="form-control" v-model="form.temperature[1]" />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <h4>{{$t('shop.info.product.wine.conservation')}}</h4>
      </div>
    </div>
    <TextField 
      v-bind:value.sync="form.keep" 
      id="updateKeep" 
      :label="$t('shop.info.product.wine.keep')" 
      :placeholder="$t('shop.info.product.wine.keep_placeholder')" 
      :labelAsColumn="true"
      :required="false"
    />
    <div class="row">
      <div class="col-lg-12">
        <h4>{{$t('shop.info.product.wine.vineyard')}}</h4>
      </div>
    </div>
    <LocalizedText 
      v-bind:value.sync="form.varieties" 
      id="updateVarieties" 
      :label="$t('shop.info.product.wine.varieties')" 
      :placeholder="$t('shop.info.product.wine.varieties_placeholder')" 
      :labelAsColumn="true"
      :required="false"
      :languageCodes="availableLanguageCodes"
      :rowClass="{'form-group':true, 'row':true, 'update-varieties':true}"
    />
    <LocalizedText 
      v-bind:value.sync="form.terroir" 
      id="updateTerroir" 
      :label="$t('shop.info.product.wine.terroir')" 
      :placeholder="$t('shop.info.product.wine.terroir_placeholder')" 
      :labelAsColumn="true"
      :required="false"
      :languageCodes="availableLanguageCodes"
      :rowClass="{'form-group':true, 'row':true, 'update-terroir':true}"
    />
    <TextField 
      v-bind:value.sync="form.ageOfVines" 
      id="updateAgeOfVines" 
      :label="$t('shop.info.product.wine.ageOfVines')" 
      :placeholder="$t('shop.info.product.wine.ageOfVines_placeholder')" 
      :labelAsColumn="true"
      :required="false"
    />
    <TextField 
      v-bind:value.sync="form.year" 
      id="updateYear" 
      :label="$t('shop.info.product.wine.year')" 
      :placeholder="$t('shop.info.product.wine.year_placeholder')" 
      :labelAsColumn="true"
      :required="false"
    />
  </div>
</template>

<style>
  .product-form .update-description textarea {
    min-height: 100px;
  }
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import WineSweetness from './WineSweetness.vue';
import LocalizedText from '@fwk-client/components/panels/input/LocalizedText.vue';
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';

@Component({
  components: {
    WineSweetness,
    LocalizedText,
    TextField,
    SwitchCheck
  }
})
export default class WineForm extends Vue {

  @Prop({
    type: Object,
    required: false
  }) readonly product!: any | undefined

  availableLanguageCodes = ['fr','en'];

  form:any = {};

  
  created() {
    // We populate the form based on the product
    this.updateFormFromWine(this.product);
  }

  updateFormFromWine(product:any) {
    this.form = {
      // INFORMATION
      capacity: product ? product.capacity : '',
      degree: product ? product.degree : '',
      isBio: product ? product.isBio : false,
      // ELABORATION
      elaboration: product ? product.elaboration : undefined,
      // TASTING
      sweetness: product ? product.sweetness : undefined,
      eye: product ? product.eye : undefined,
      nose: product ? product.nose : undefined,
      mouth: product ? product.mouth : undefined,
      pairing: product ? product.pairing : undefined,
      temperature: product && product.temperature ? product.temperature : [undefined, undefined],
      // CONSERVATION
      keep: product ? product.keep : '',
      // VINEYARD
      varieties: product ? product.varieties : undefined,
      terroir: product ? product.terroir : undefined,
      ageOfVines: product ? product.ageOfVines : '',
      year: product ? product.year : ''
    };
  }

  updateFormToStore(form:any) {

    // We check the temperature
    var temperature = form.temperature;
    if((form.temperature[0] == undefined || form.temperature[0] == null || form.temperature[0] == "") &&
        (form.temperature[1] == undefined || form.temperature[1] == null || form.temperature[1] == "")) {
      temperature = undefined;
    }
    else if(form.temperature[0] == undefined || form.temperature[0] == null || form.temperature[0] == "") {
      temperature = [form.temperature[1]]
    }
    else if(form.temperature[1] == undefined || form.temperature[1] == null || form.temperature[1] == "") {
      temperature = [form.temperature[0]]
    }
    form.temperature = temperature;

    // We check the keep
    if(form.keep == "") {
      form.keep = undefined;
    }

    // We check the ageOfVines
    if(form.ageOfVines == "") {
      form.ageOfVines = undefined;
    }

    // We check the year
    if(form.year == "") {
      form.year = undefined;
    }

    return form;
  }

  @Watch('form', { deep: true })
  onFormChange(val: any, oldVal: any) {

    var outputForm = this.updateFormToStore({...val})

    this.$emit('update:value', outputForm);
  }

  @Watch('product', { deep: true })
  onProductChange(val: any, oldVal: any) {
    this.updateFormFromWine(val);
  }
  
}
</script>