<template>
  <div class="ibox products">
    <div class="ibox-title">
      <h2>{{ $t('shop.products.'+labelsKey+'.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <p v-if="showNumberOfRows">Number of rows: {{numberOfRows}}</p>
    
      <b-table 
          outlined striped
          :items="products"
          :fields="listFields"
          ref="listItems">
        <template v-slot:cell(ranking)="row">
          <a href="javascript:void(0)" @click="updateRankingUp(row.item)"><i class="fa fa-sort-up"></i></a>
          <a href="javascript:void(0)" @click="updateRankingDown(row.item)"><i class="fa fa-sort-down"></i></a>
        </template> 
        <template v-slot:cell(select)="row">
          <p-check class="p-default p-curve p-primary" color="success" :value="row.item._id" v-model="listItemsSelected"></p-check>
        </template>       
        <template v-slot:cell(title)="row">
          {{row.item.title}}
          <span v-if="row.item.subtitle && row.item.subtitle!=''">
            <br/>{{row.item.subtitle}}
          </span>
        </template> 
        <template v-slot:cell(isActivated)="row">
          <i :class="['fa','fa-check',row.item.isActivated ? 'active' : 'disabled']"></i>
        </template> 
        <template v-slot:cell(description)="row">
          <div v-html="getLocalizedText(row.item.description)" ></div>
        </template>
        <template v-slot:cell(options)="row">
          <span v-if="isProductUpdateAllowed">
            <a href="javascript:void(0)" @click="showUpdateModal(row.item)">{{$t('shop.products.'+labelsKey+'.options.update')}}</a>
            /
          </span>
          <span v-if="isProductPublishAllowed">
            <a href="javascript:void(0)" @click="showPublishModal(row.item)">{{$t('shop.products.'+labelsKey+'.options.publish')}}</a>
            /
          </span>
          <span v-if="isProductRemoveAllowed">
            <a href="javascript:void(0)" @click="confirmRemoveItem(row.item)">{{$t('shop.products.'+labelsKey+'.options.delete')}}</a>
          </span>
        </template>
      </b-table>

      <button v-if="isProductCreateAllowed" class="btn btn-primary" @click="showCreateItemModal()">{{$t('shop.products.'+labelsKey+'.create-button')}}</button>

      <b-modal size="xl" ref="createItemModal" :title="$t('shop.products.'+labelsKey+'.create.title')" hide-footer lazy>
        <ProductForm :mode="mode" v-on:product-created="onItemCreated" />
      </b-modal>

      <b-modal size="xl" ref="updateItemModal" :title="$t('shop.products.'+labelsKey+'.update.title', {productID: itemToUpdate._id})" hide-footer lazy>
        <ProductForm :mode="mode" :product="itemToUpdate" v-on:product-updated="onItemUpdated" />
      </b-modal>

      <b-modal size="xl" ref="publishItemModal" :title="$t('shop.products.'+labelsKey+'.publish.title', {productID: itemToPublish._id})" hide-footer lazy>
        <PublishProduct :mode="mode" :product="itemToPublish" />
      </b-modal>

      <b-modal ref="removeItemModal" 
          hide-header
          @ok="removeItem">
        {{$t('shop.products.'+labelsKey+'.delete-confirmation', {productID: itemToRemove._id, productTitle: itemToRemove.title})}}
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
  .fa-check.active, .fa-user-circle-o.active {
    color:green;
  }
  .fa-check.disabled, .fa-user-circle-o.disabled {
    color:red;
  }

  
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, ComputedRef } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import ProductForm from './ProductForm.vue';
import PublishProduct from './Publish.vue';
import { languagesTypes } from '@fwk-client/store/types';
import { formatDate } from '@igotweb-node-api-utils/formatter';

import { useProductAdmin } from '../../../../composables/useProductAdmin';
import { useCategoryAdmin } from '../../../../composables/useCategoryAdmin';

export default defineComponent({
  props: {
    mode: {
      type: String as PropType<"PRODUCT"|"ACTIVITY">,
      default: "PRODUCT"
    }
  },
  components: {
    ProductForm, 
    PublishProduct
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const mode = props.mode;
    const labelsKey = (mode == "PRODUCT" ? "products" : "activities");

    const { products, isListLoading, isProductUpdateAllowed,
    isProductCreateAllowed,
    isProductRemoveAllowed,
    isProductPublishAllowed, removeProduct, updateRanking }  = useProductAdmin(props, context);
    const { getCategoryFromID } = useCategoryAdmin(props, context);

    const itemToRemove:Ref<any> = ref({});
    const itemToUpdate:Ref<any> = ref({});
    const itemToPublish:Ref<any> = ref({});

    const listLoading:Ref<boolean> = computed(() => {
      return isListLoading.value;
    })

    const listItemsSelected:Ref<any[]> = ref([]);

    const listItems:Ref<HTMLElement|null> = ref(null);
    const removeItemModal:Ref<HTMLElement|null> = ref(null);
    const createItemModal:Ref<HTMLElement|null> = ref(null);
    const updateItemModal:Ref<HTMLElement|null> = ref(null);
    const publishItemModal:Ref<HTMLElement|null> = ref(null);

    const isMultiSelectAllowed:Ref<boolean> = ref(false);
    const showNumberOfRows:Ref<boolean> = ref(false);

    const numberOfRows:Ref<number> = computed(() => {
        return products.value.length
    })

    const productTypeFormatter = () => {
      return (value:any, key:any, item:any) => {
        return app.$t('shop.products.'+labelsKey+'.types.'+value) as string;
      }
    }

    const listFields = [
      {
        key: "select",
        label: "",
        class: (isMultiSelectAllowed.value ? "" : "hidden"),
      },
      {
        key: "ranking",
        label: "",
      },
      {
        key: "type",
        label: "",
        formatter: productTypeFormatter()
      },
      {
        key: "reference",
        label: ""
      },
      {
        key: "title",
        label: "",
      },
      {
        key: "category",
        label: "",
        formatter: (value:any, key:any, item:any) => {
          if(value && value.title) { return value.title }
          if(item.category_id) {
            var category = getCategoryFromID(item.category_id);
            if(category) { return category.title; }
          }
          return ''
        }
      },
      {
        key: "creationDateTime",
        label: "",
        formatter: (value:Date, key:any, item:any) => {
          return formatDate(value, currentLanguageCode.value);
        }
      },
      {
        key: "isActivated",
        label: ""
      },
      {
        key: "price.amount",
        label: "",
        formatter: (value:Date, key:any, item:any) => {
          if(mode == "ACTIVITY") {
            return app.formatPriceAmount(item.pricePerPerson);  
          }
          return app.formatPriceAmount(item.price);
        }
      },
      {
        key: "description",
        label: ""
      },
      {
        key: "pictures",
        label: "",
        formatter: (value:any, key:any, item:any) => {
          if(!item.pictures) {
            return 0;
          }
          return item.pictures.length;
        }
      },
      {
        key: "options"
      }
    ];

    onMounted(() => {
      listFields[2].label = app.$t('shop.products.'+labelsKey+'.headers.type') as string;
      listFields[3].label = app.$t('shop.products.'+labelsKey+'.headers.reference') as string;
      listFields[4].label = app.$t('shop.products.'+labelsKey+'.headers.title') as string;
      listFields[5].label = app.$t('shop.products.'+labelsKey+'.headers.category') as string;
      listFields[6].label = app.$t('shop.products.'+labelsKey+'.headers.creationDateTime') as string;
      listFields[7].label = app.$t('shop.products.'+labelsKey+'.headers.isActivated') as string;
      listFields[8].label = app.$t('shop.products.'+labelsKey+'.headers.price') as string;
      listFields[9].label = app.$t('shop.products.'+labelsKey+'.headers.description') as string;
      listFields[10].label = app.$t('shop.products.'+labelsKey+'.headers.thumbnails') as string;
      listFields[11].label = app.$t('shop.products.'+labelsKey+'.headers.options') as string;
    })

    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const onItemCreated = () => {
      // @ts-ignore
      createItemModal.value.hide()
    }

    const onItemUpdated = (item:any) => {
      itemToUpdate.value = item;
    }
    
    const confirmRemoveItem = (item:any) => {
      itemToRemove.value = item;
      // @ts-ignore
      removeItemModal.value.show()
    }

    const showUpdateModal = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      updateItemModal.value.show()
    }

    const showCreateItemModal = () => {
      // @ts-ignore
      createItemModal.value.show()
    }

    const removeItem = () => {
      removeProduct(itemToRemove.value._id).then((removed:boolean) => {
        // We reset the user to remove
        itemToRemove.value = {};
      })
    }

    const updateRankingUp = (item:any) => {
      updateProductRanking(item, "up");
    }

    const updateRankingDown = (item:any) => {
      updateProductRanking(item, "down");
    }

    const showPublishModal = (item:any) => {
      itemToPublish.value = item;
      // @ts-ignore
      publishItemModal.value.show()
    }


    const updateProductRanking = (product:any, direction:string) => {
      updateRanking(product._id, direction).then((response:any) => {
        if(response.updated) {  
          var product = response.product;
          // We emit event as user is updated
          onItemUpdated(product);
        }
      })
    }

    return {
      listLoading,
      labelsKey,
      mode,
      products,
      listFields,
      listItems,
      listItemsSelected,
      isProductUpdateAllowed,
      isProductCreateAllowed,
      isProductRemoveAllowed,
      isProductPublishAllowed,
      showNumberOfRows,
      numberOfRows,
      
      showCreateItemModal,
      createItemModal,
      onItemCreated,
      
      
      removeItemModal,
      itemToRemove,
      confirmRemoveItem,
      removeItem,

      showUpdateModal,
      updateItemModal,
      itemToUpdate,
      updateRankingUp,
      updateRankingDown,
      onItemUpdated,

      itemToPublish,
      publishItemModal,
      showPublishModal
    }
  }
})
</script>