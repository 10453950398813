import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { types as shopTypes } from '@root/src/client/modules/shop/store';
import { useShopAdmin } from "./useShopAdmin";
import { formatDateForInput } from "@igotweb-node-api-utils/formatter";
import { useWeekdays } from '@fwk-client/composables/useWeekdays';

interface AvailabilityAdminInput {
  storage?:"local"|"store";
}

export function useAvailabilityAdmin(props:AvailabilityAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();
  const { weekDays } = useWeekdays(props, {emit});
  
  const { selectedShop, callShopAdmin } = useShopAdmin(props, {emit})

  const filters:any = reactive({
    activityID: undefined,
  })

  var storage = props.storage ? props.storage : "store";

  var isListLoading:Ref<boolean> = ref(false);

  const localAvailabilities = ref([]);

  const isAvailabilityUpdateAllowed:Ref<boolean> = ref(false);
  const isAvailabilityCreateAllowed:Ref<boolean> = ref(false);
  const isAvailabilityRemoveAllowed:Ref<boolean> = ref(false);
  const isAvailabilityPublishAllowed:Ref<boolean> = ref(false);

  const getShopAvailabilities = async (shopID:string, activityID?:string) => {
    var input:any = {
      activityID:activityID ? activityID : undefined
    }
    var response:any = {};
    try {
      response = await callShopAdmin('/shop/'+shopID+'/availabilities/list', input);
    }
    catch(error:any) {
      console.log(error);
    }
    return response;
  }

  const updateListAvailabilities = async () => {

    isListLoading.value = true;
    try {
      if(selectedShop.value != undefined) {
        var response = await getShopAvailabilities(selectedShop.value.shop._id, filters.activityID);
        if(storage == "local") {
          localAvailabilities.value = response.availabilities ? response.availabilities : [];
        }
        else {
          store.commit('shop/'+shopTypes.mutations.SET_USER_AVAILABILITIES, response.availabilities ? response.availabilities : []);
        }
        if(response.options) {
          isAvailabilityUpdateAllowed.value = response.options.isUpdateAllowed;
          isAvailabilityCreateAllowed.value = response.options.isCreateAllowed;
          isAvailabilityRemoveAllowed.value = response.options.isRemoveAllowed;
          isAvailabilityPublishAllowed.value = response.options.isPublishAllowed;
        }
      }
      else {
        if(storage == "local") {
          localAvailabilities.value = [];
        }
        else {
          store.commit('shop/'+shopTypes.mutations.SET_USER_AVAILABILITIES, []);
        }
      }
      
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }
  updateListAvailabilities();

  const getAvailabilityFromID = (availabilityID:string) => {
    var availability = availabilities.value.filter((availability:any) => {
      return availability._id == availabilityID
    })
    if(availability.length == 1) { return availability[0]; }
    return null;
  }

  
  const availabilities:Ref<any[]> = computed(() => {
    if(storage == "local") {
      return localAvailabilities.value;
    }
    return store.getters['shop/'+shopTypes.getters.GET_USER_AVAILABILITIES];
  })


  // We check when there is a new availability selected
  watch(
    selectedShop,
    (val:any, oldVal:any) => {
      // We update the categories when the selected agency is updated
      updateListAvailabilities();
    },
    { deep: false }
  )

  const availabilityForm:any = reactive({
    title: '',
    startDate:null,
    endDate:null,
    slotsPerWeekday:null,
    minDaysForAvailability:null,
    maxDaysForAvailability:null
  });

  const updateAvailabilityFormForUpdate = (availability:any) => {
    availabilityForm.availabilityID = (availability && availability._id) ? availability._id : undefined,
    availabilityForm.title =  availability.title
    availabilityForm.startDate =  availability.startDate ? formatDateForInput(availability.startDate) : null
    availabilityForm.endDate = availability.endDate ? formatDateForInput(availability.endDate) : null;
    availabilityForm.slotsPerWeekday = availability.slotsPerWeekday;
    availabilityForm.minDaysForAvailability = availability.minDaysForAvailability;
    availabilityForm.maxDaysForAvailability = availability.maxDaysForAvailability;
  }

  const getSlotsPerWeekday = (availabilityForm:any) => {
    // We update the value to only keep days in which we have slots
    let slotsPerWeekdayToStore:any = null;
    for(let weekday of weekDays) {
      if(availabilityForm.slotsPerWeekday[weekday] && availabilityForm.slotsPerWeekday[weekday].length > 0) {
        if(slotsPerWeekdayToStore == null) {
          slotsPerWeekdayToStore = {};
        }
        slotsPerWeekdayToStore[weekday] = JSON.parse(JSON.stringify(availabilityForm.slotsPerWeekday[weekday]))
      }
    }
    return slotsPerWeekdayToStore;
  }

  const createAvailability = async (extraForm?:any) => {
    var result:any = {
      created: false
    }

    var input = {
      "shopID" : selectedShop.value.shop._id,
      ...availabilityForm,
      slotsPerWeekday : getSlotsPerWeekday(availabilityForm),
      ...(extraForm ? extraForm : {})
    }

    try {
      var response = await callShopAdmin('/shop/'+selectedShop.value.shop._id+'/availabilities/create', input);
      if(response.created) {  
        // We update the form
        updateAvailabilityFormForUpdate(response.availability);

        // We update the list
        updateListAvailabilities();

        result.created = true;
        result.availability = response.availability;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const updateAvailability = async (extraForm?:any) => {

    var result:any = {
      updated: false
    }

    var input:any = {
      ...availabilityForm,
      slotsPerWeekday : getSlotsPerWeekday(availabilityForm),
      ...(extraForm ? extraForm : {})
    }

    try {
      var response = await callShopAdmin('/shop/'+selectedShop.value.shop._id+'/availability/'+availabilityForm.availabilityID+'/update', input);
      if(response.updated) {  
        // We update the form
        updateAvailabilityFormForUpdate(response.availability);

        // We update the list
        updateListAvailabilities();

        result.updated = true;
        result.availability = response.availability;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const removeAvailability = async (availabilityID:string) => {
    isListLoading.value = true;
    try {
      var response = await callShopAdmin('/shop/'+selectedShop.value.shop._id+'/availability/'+availabilityID+'/remove');
      if(response.removed) {
        updateListAvailabilities();
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return true;
  }

  const getPublishTargetAvailabilities = async () => {
    return callShopAdmin('/shop/'+selectedShop.value.shop._id+'/deployment/availabilities').then((response:any) => {
      return response.availabilities
    });
  }

  const publishAvailabilities = async () => {
    var result:any = {
      published: false
    }

    try {
      var path = '/shop/'+selectedShop.value.shop._id+'/deployment/publish-availabilities';
      var response = await callShopAdmin(path);
      if(response.availabilities) {  
        result.published = true;
        result.availabilities = response.availabilities;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const unavailablePeriodForm:any = reactive({
    availabilityID: undefined,
    reason: '',
    startDate:null,
    endDate:null
  });

  watch(
    () => unavailablePeriodForm.startDate,
    (val:any, oldVal:any) => {
      // We update the end Date if not defined and if the start date is updated
      if(val && (!unavailablePeriodForm.endDate || unavailablePeriodForm.endDate == oldVal)) {
        unavailablePeriodForm.endDate = val;
      }
    },
    { deep: false }
  )

  const addUnavailablePeriod = async () => {
    var result:any = {
      updated: false
    }

    var input = {
      ...unavailablePeriodForm
    }

    try {
      var response = await callShopAdmin('/shop/'+selectedShop.value.shop._id+'/availability/'+unavailablePeriodForm.availabilityID+'/add-unavailable-period', input);
      if(response.updated) {  
        // We update the list
        updateListAvailabilities();

        result.updated = true;
        result.availability = response.availability;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const removeUnavailablePeriod = async (availabilityID:string, unavailablePeriodIndex:number) => {
    var result:any = {
      updated: false
    }

    var input:any = {
      unavailablePeriodIndex
    }

    try {
      var response = await callShopAdmin('/shop/'+selectedShop.value.shop._id+'/availability/'+availabilityID+'/remove-unavailable-period', input);
      if(response.updated) {  

        // We update the list
        updateListAvailabilities();

        result.updated = true;
        result.availability = response.availability;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  return {
    isListLoading,
    availabilities,
    filters,
    getAvailabilityFromID,
    availabilityForm,
    updateAvailabilityFormForUpdate,
    updateAvailability,
    createAvailability,
    removeAvailability,
    getPublishTargetAvailabilities,
    publishAvailabilities,
    isAvailabilityUpdateAllowed,
    isAvailabilityCreateAllowed,
    isAvailabilityRemoveAllowed,
    isAvailabilityPublishAllowed,
    getShopAvailabilities,
    updateListAvailabilities,
    unavailablePeriodForm,
    addUnavailablePeriod,
    removeUnavailablePeriod
  }
  
}