<template>
  <TinyMceEditor
    ref="tinyMceEditor"
    :id="id" 
    v-model="input" 
    :required="required"
    :class="{...inputCSSClass, 'required':required, ...classes}" 
    :init="{
      plugins : 'advlist lists link image table code help wordcount',
      toolbar: 'bullist numlist code',
      //toolbar : 'code',
      menubar : false,
      language: 'fr_FR',
      element_format: 'xhtml',
      extended_valid_elements: 'i[class],em,b,strong',
      content_style: options.style
    }"
    api-key="hfucujcm3bwbw8lhf642agjt6lo0nkzii3xv13pmg8sx0pjn"
  ></TinyMceEditor>
</template>

<style>

  .tox-tinymce-aux {
    z-index:2200 !important;
  }

  
</style>

<script lang="ts">
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericInput from '@fwk-client/components/mixins/GenericInput.vue';
import TinyMceEditor from "@tinymce/tinymce-vue";

import type { LocalizedContentOptions } from './interfaces';

@Component({
  components : {
    TinyMceEditor : TinyMceEditor as any
  }
})
export default class TinyMce extends mixins<GenericInput<any>>(GenericInput) {

  @Prop({
    type: Object,
    required: false,
  }) readonly options?: LocalizedContentOptions

  get inputPlaceholder() {
    return (this.placeholder && this.placeholder != "") ? this.placeholder : "";
  }

  created() {
    
  }

  mounted() {
    // Prevent Bootstrap dialog from blocking focusin
    document.addEventListener('focusin',this.onFocusinTinyMce)
  }

  destroyed() {
    document.removeEventListener('focusin', this.onFocusinTinyMce);
  }

  onFocusinTinyMce(e:Event) {
      // @ts-ignore
      if (e.target.closest(".tox-tinymce, .tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
        e.stopImmediatePropagation();
      }
  }

  @Watch('input', { deep: true })
  onInputChange(val: any, oldVal: any) {
    this.$emit('updated', val);
  }


 
  
}
</script>