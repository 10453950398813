<template>
  
    <div class="sweetness">
      <label>
        <input type="checkbox" v-model="isActivated"> {{ $t('shop.info.product.wine.sweetness.activate') }}
      </label>
      <div v-if="isActivated">
        <div class="legend">
          <span>{{ $t('shop.info.product.wine.sweetness.dry') }}</span>
          <span>{{ $t('shop.info.product.wine.sweetness.medium-dry') }}</span>
          <span>{{ $t('shop.info.product.wine.sweetness.medium-sweet') }}</span>
          <span>{{ $t('shop.info.product.wine.sweetness.sweet') }}</span>
        </div>
        <div class="scale">
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </div>
        <input type=range min=0 max=100 value=0 step=1 v-model="range" />
      </div>
      
    </div>

</template>

<style>
.sweetness input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  position: absolute;
  bottom:0px;
}

.sweetness input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.sweetness input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

.sweetness input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: transparent;
}

/* Special styling for WebKit/Blink */
.sweetness input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 0px;
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background: #000000;
  cursor: pointer;
  margin-top: -7px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* All the same stuff for Firefox */
.sweetness input[type=range]::-moz-range-thumb {
  border: 0px;
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background: #000000;
  cursor: pointer;
}

/* All the same stuff for IE */
.sweetness input[type=range]::-ms-thumb {
  border: 0px;
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background: #000000;
  cursor: pointer;
}

.sweetness input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
}

.sweetness input[type=range]:focus::-webkit-slider-runnable-track {
  background: transparent;
}

.sweetness input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
}

.sweetness input[type=range]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.sweetness input[type=range]::-ms-fill-lower {
  background: transparent;
}
.sweetness input[type=range]:focus::-ms-fill-lower {
  background: transparent;
}
.sweetness input[type=range]::-ms-fill-upper {
  background: transparent;
}
.sweetness input[type=range]:focus::-ms-fill-upper {
  background: transparent;
}

.sweetness .scale {
    position: relative;
    width:436px;
    height: 15px;
    background:transparent;
    border-bottom:4px solid #555;
    margin:0px 7px;
}

.sweetness .scale > i {
    width:2px;
    height: 15px;
    background:#555;
    position:absolute;
}

.sweetness .legend {
    display:flex;
}

.sweetness .legend > span {
    flex-grow:1;
    text-align:center;
    text-transform: uppercase;
}

.sweetness .legend > span:first-child {
    text-align:left
}

.sweetness .legend > span:last-child {
    text-align:right
}

.sweetness {
    position:relative;
    width: 450px;
}
.sweetness .scale > i:nth-child(1) {
    left:0px;
}
.sweetness .scale > i:nth-child(2) {
    left:143px;
}
.sweetness .scale > i:nth-child(3) {
    left:287px;
}
.sweetness .scale > i:nth-child(4) {
    right:0px;
}
</style>

<script lang="ts">
import { Component, Prop, Watch  } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericInput from '@fwk-client/components/mixins/GenericInput.vue';

@Component({
  components: {
    
   }
})
export default class WineSweetness extends mixins<GenericInput<number>>(GenericInput) {

  range:string|undefined = this.value !== undefined ? this.value + "" : "50";

  input:any = this.value !== undefined ? this.value : undefined;
  
  isActivated = this.value !== undefined ? true : false;

  created() {
    
  }

  @Watch('range')
  onRangeChange(val: any, oldVal: any) {
    this.input = parseInt(val);
  }

  @Watch('isActivated')
  onActivationChange(val: any, oldVal: any) {
    if(!val) {
      // We reset the input when disabled
      this.input = undefined;
      this.range = undefined;
    }
    else {
      if(this.range) {
        this.input = parseInt(this.range)
      }
    }
  }
  
}
</script>