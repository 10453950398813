<template>
  <validation-provider ref="validator" :name="id" :rules="contentValidationRule" mode="eager" v-slot="{ errors, classes }" slim>
    <div :class="{...rowCSSClass, ...classes, 'localizedContent':true}">
      <label :for="id" :class="{...labelCSSClass}">{{ inputLabel }} <small v-if="required">*</small></label>
      <div :class="{...fieldCSSClass}">
        <ul class="nav nav-tabs">
          <li v-for="(languageCode, index) in languageCodes" :key="index">
            <a :class="{'nav-link':true, 'active':(languageCode == selectedLanguageCode)}" href="javascript:void(0)" @click="selectedLanguageCode = languageCode">
              <img :src="require('@public/img/flags/' + languageCode + '.png')" :alt="$t('languages.'+ languageCode)" /> 
            </a>
          </li>
        </ul>
        <CkEditor 
          v-if="input && options.editor!='tinymce'" 
          :id="id" 
          :componentClass="{...inputCSSClass, 'required':required, ...classes}" 
          :value="input[selectedLanguageCode]" 
          v-on:updated="onValueUpdated"
          :required="required"
          :options="options"
        ></CkEditor>
        <TinyMce 
          v-if="options.editor=='tinymce'" 
          :id="id" 
          :componentClass="{...inputCSSClass, 'required':required, ...classes}" 
          :value="input[selectedLanguageCode]" 
          v-on:updated="onValueUpdated"
          :required="required"
          :options="options"
        ></TinyMce>

        <span :class="{...controlCSSClass}" v-if="errors.length > 0">{{ errors[0] }}</span>
      </div>
    </div>
  </validation-provider>
</template>

<style>
  
</style>

<script lang="ts">
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericInput from '@fwk-client/components/mixins/GenericInput.vue';
import { extend } from "@fwk-node-modules/vee-validate";

import Vue from '@fwk-node-modules/vue';
import { LocalizedText } from '@igotweb-node-api/api/models/interface/localization';

import CkEditor from './content/CkEditor.vue'
import TinyMce from './content/TinyMce.vue';
import type { LocalizedContentOptions } from './content/interfaces';


@Component({
  components : {
    CkEditor,
    TinyMce
  }
})
export default class LocalizedContent extends mixins<GenericInput<any>>(GenericInput) {

  @Prop({
    type: Array,
    required: true,
  }) readonly languageCodes!: string[]

  @Prop({
    type: Object,
    required: false,
  }) readonly options?: LocalizedContentOptions

  selectedLanguageCode:string = this.languageCodes[0];

  input:any = (this.value !== undefined && this.value != "") ? this.getInputFromValue(this.value) : this.getDefaultInput();

  get inputLabel() {
    return (this.label && this.label != "") ? this.label : "";
  }

  created() {
    this.addContentValidation();
  }

  mounted() {
    
  }

  destroyed() {
    
  }

  validate() {
    // @ts-ignore
    return this.$refs.validator.validate();
  }

  getDefaultInput() {
    return this.languageCodes.reduce((acc:any,current)=> ( acc[current]='' ,acc) ,{});
  }

  getInputFromValue(value:LocalizedText) {
    var updatedContent:LocalizedText = {};
    for(var languageCode of Object.keys(value)) {
      var localizedContent = value[languageCode];
      updatedContent[languageCode] = Vue.filter('staticPath')(localizedContent, this.options ? this.options.staticsDomain : undefined);
    }
    return updatedContent;
  }

  get contentValidationRule() {
    var validation:any = {}
    if(this.required) {
      validation = {
        ...validation,
        "contentRequired" : true,
        "required" : true
      }
    }
    return validation;
  }

  addContentValidation() {
    var componentInstance = this;
    extend('contentRequired',{
      params: [],
      validate(content, params):Promise<boolean|string> {

        if(componentInstance.hasDefinedValue(componentInstance.input)) {
          return Promise.resolve(true);
        }
        return Promise.resolve("content is required");
      }
    });
  }

  hasDefinedValue(val:any):boolean {
    for(var languageCode of this.languageCodes) {
      if(val && val[languageCode] && val[languageCode] != "") {
        // We found a non empty value
        return true;
      }
    }
    return false;
  }

  @Watch('value')
  onValueChange(val: any, oldVal: any) {
    if(!val || val == "") {
      this.input = this.getDefaultInput();
    }
    else {
      // We update the computed value only in case on real update in the value (to avoid updating after an update:value synchro)
      var computedInputFromValue = this.getInputFromValue(val);
      for(var languageCode of Object.keys(computedInputFromValue)) {
        var localizedComputedInput = computedInputFromValue[languageCode];
        if(localizedComputedInput != this.input[languageCode]) {
          this.input[languageCode] = localizedComputedInput
        }
      }
    }
    // @ts-ignore
    this.$refs.validator.validate(val);
  }

  @Watch('input', { deep: true })
  onInputChange(val: any, oldVal: any) {
    // We check that we have at least one non empty value
    if(this.hasDefinedValue(val)) {      
      // We found a non empty value
        return this.$emit('update:value', val);
    }
    // When all values are empty, the localized text should be undefined
    return this.$emit('update:value', undefined);
  }

  onValueUpdated(val: any, oldVal: any) {
    Vue.set(this.input,this.selectedLanguageCode,val);
  }
  
}
</script>