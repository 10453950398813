<template>
  
    <form role="form" @submit="onFormSubmit" name="publish-product">

      <h4>{{$t('shop.products.products.publish.product')}}</h4>
      <ul>
        <li>{{$t('shop.info.product.title')}}: {{product.title}}
        <li>{{$t('shop.info.product.isActivated')}}: <i :class="['fa','fa-check',product.isActivated ? 'active' : 'disabled']"></i></li>  
        <li v-if="mode == 'ACTIVITY'">{{$t('shop.info.activity.price.label')}}: {{ formatPriceAmount(product.pricePerPerson) }}</li>
        <li v-else>{{$t('shop.info.product.price.label')}}: {{ formatPriceAmount(product.price) }}</li>
        <li>{{$t('shop.products.products.headers.thumbnails')}}: {{ product.pictures.length }}</li>
        <li v-if="false">{{$t('shop.info.product.description')}}: {{ getLocalizedText(product.description) }}</li>
        <li>{{$t('shop.info.product.lastUpdateDateTime')}}: {{ formatDateTime(product.lastUpdateDateTime) }}</li>
      </ul>


      <div class="production-product">
        <h4>{{$t('shop.products.products.publish.production-product')}}</h4>
        <ul v-if="productionProduct">
          <li>{{$t('shop.info.product.title')}}: {{productionProduct.title}}
          <li>{{$t('shop.info.product.isActivated')}}: <i :class="['fa','fa-check',productionProduct.isActivated ? 'active' : 'disabled']"></i></li>  
          <li v-if="mode == 'ACTIVITY'">{{$t('shop.info.activity.price.label')}}: {{ formatPriceAmount(productionProduct.pricePerPerson) }}</li>
          <li v-else>{{$t('shop.info.product.price.label')}}: {{ formatPriceAmount(productionProduct.price) }}</li>
          <li>{{$t('shop.products.products.headers.thumbnails')}}: {{ productionProduct.pictures.length }}</li>
          <li v-if="false">{{$t('shop.info.product.description')}}: {{ getLocalizedText(productionProduct.description) }}</li>
          <li>{{$t('shop.info.product.lastUpdateDateTime')}}: {{ formatDateTime(productionProduct.lastUpdateDateTime) }}</li>
        </ul>
        <span v-if="productionProduct == null">{{$t('shop.products.products.publish.no-production-product')}}</span>
      </div>
      
      <button class="btn btn-primary ladda-button publish-product" data-style="zoom-in" type="submit">{{$t('shop.products.products.publish.button')}}</button>
    </form>

</template>

<style scoped>
  .fa-check.active, .fa-user-circle-o.active {
    color:green;
  }
  .fa-check.disabled, .fa-user-circle-o.disabled {
    color:red;
  }

  
</style>

<script lang="ts">
import { toRefs, Ref, ref, defineComponent, PropType, computed, onMounted } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';
import { languagesTypes } from '@fwk-client/store/types';
import { formatDate } from '@igotweb-node-api-utils/formatter';

import { useShopAdmin } from '../../../../composables/useShopAdmin';

export default defineComponent({
  props: {
    mode: {
      type: String as PropType<"PRODUCT"|"ACTIVITY">,
      default: "PRODUCT"
    },
    product: Object as PropType<any>
  },
  components: {
      
  },
  setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { selectedShop, callShopAdmin } = useShopAdmin(props, context);

      const productionProduct:Ref<any> = ref(undefined);

      const { product, mode } = toRefs(props);

      var laddaSubmit:Ladda.LaddaButton|null = null;

      onMounted(() => {
        var publishButton:HTMLButtonElement|null = document.querySelector( 'form[name=publish-product] button.ladda-button.publish-product' );
        laddaSubmit = Ladda.create(publishButton!);
      })

      var isProductionProductLoading:boolean = false;
      const getProductionProduct = () => {
        let input = {
          mode: mode.value
        }
        isProductionProductLoading = true;
        callShopAdmin('/shop/'+selectedShop.value.shop._id+'/deployment/product/'+product.value._id, input).then((response:any) => {
          if(response.product) {  
            productionProduct.value = response.product;
          }
          else {
            // There is no production product
            productionProduct.value = null;
          }

          isProductionProductLoading = false;
        });
      }
      // We load the production product.
      getProductionProduct();

      const onFormSubmit = (evt:Event) => {
        evt.preventDefault();

        var input = {
          productID: product.value._id,
          mode: mode.value
        }

        var path = '/shop/'+selectedShop.value.shop._id+'/deployment/publish-product';

        laddaSubmit!.start();

        return callShopAdmin(path, input).then((response:any) => {
          laddaSubmit!.stop();
          if(response.product) {  
            productionProduct.value = response.product;
          }
        })
      }

    const currentLanguageCode = $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
    
    const formatDateTime = (date:Date) => {
      return formatDate(date, currentLanguageCode);
    }

    return {
      formatDateTime,
      isProductionProductLoading,
      product,
      productionProduct,
      onFormSubmit,
      mode
    }
  }
})
</script>