<template>
  <div class="ckeditor-component">
    <CKEditor 
      v-if="editorClass"
      v-bind="$attrs"
      v-model="input" 
      ref="ckeditor" 
      :editor="editorClass" 
      :config="config" 
      :class="componentClass"
      @focus="toggleEditorFocus(true)"
      @blur="toggleEditorFocus(false)"
      @ready="onEditorReady"
    ></CKEditor>
    <div v-if="options && options.allowWordCount" class="wordCount">
      {{$t('localizedContent.words')}}: {{nbWords}} - {{$t('localizedContent.characters')}}: {{nbCharacters}}
    </div>
</div>
        
</template>

<style>

  :root {
    --ck-z-default: 2200
  }

  .localizedContent .nav.nav-tabs li {
    margin-bottom: -1px;
  }

  .localizedContent.has-success .nav-tabs, 
  .localizedContent.has-success .ck.ck-toolbar {
    border-color: #1ab394;
  }

  .localizedContent.has-error .nav-tabs,
  .localizedContent.has-error .ck.ck-toolbar {
    border-color: #ed5565;
  }

  .localizedContent.has-success .nav-tabs .nav-link.active {
    border-color: #1ab394 #1ab394 #fff;
  }  

  .localizedContent.has-error .nav-tabs .nav-link.active {
    border-color: #ed5565 #ed5565 #fff;
  }

  .localizedContent .ck.ck-toolbar {
    border-top:0px;
  }

  .localizedContent.has-success .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused),
  .localizedContent.has-success .ck.ck-editor__main>.ck-source-editing-area>textarea {
    border-color: var(--ck-color-base-border) #1ab394 #1ab394 ;
  }  

  .localizedContent.has-error .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused),
  .localizedContent.has-error .ck.ck-editor__main>.ck-source-editing-area>textarea {
    border-color: var(--ck-color-base-border) #ed5565 #ed5565 ;
  }

  .localizedContent .ck.ck-editor__main>.ck-editor__editable,
  .localizedContent .ck.ck-editor__main>.ck-source-editing-area {
    min-height:200px;
  }

  .localizedContent .wordCount { 
    text-align: right;
    padding: 5px;
    border: 1px solid var(--ck-color-toolbar-border);
    border-top: 0px;
    background-color: var(--ck-color-toolbar-background);
  }
  
</style>

<script lang="ts">
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericInput from '@fwk-client/components/mixins/GenericInput.vue';


import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Bold, Italic } from '@ckeditor/ckeditor5-basic-styles';
import { Link, AutoLink } from '@ckeditor/ckeditor5-link';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { FontColor } from '@ckeditor/ckeditor5-font';
import { Indent, IndentBlock } from '@ckeditor/ckeditor5-indent';
import { ListProperties } from '@ckeditor/ckeditor5-list';
import { Alignment } from '@ckeditor/ckeditor5-alignment';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { RemoveFormat } from '@ckeditor/ckeditor5-remove-format';
import { Autoformat } from '@ckeditor/ckeditor5-autoformat';
import { HorizontalLine } from '@ckeditor/ckeditor5-horizontal-line';
import { MediaEmbed } from '@ckeditor/ckeditor5-media-embed';
import { SourceEditing } from '@ckeditor/ckeditor5-source-editing';
import { Table, TableToolbar, TableProperties, TableCellProperties } from '@ckeditor/ckeditor5-table';
import { Image, ImageUpload, ImageInsert, AutoImage, ImageResizeEditing, ImageResizeHandles } from '@ckeditor/ckeditor5-image';
import { WordCount } from '@ckeditor/ckeditor5-word-count';

import { CkEditorUploadAdapter, CkEditorUploadAdapterOptions } from '../../../../models/ckeditor/CkEditorUploadAdapter';

//import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';

import { CustomEditor } from '../../../../models/ckeditor/CustomEditor';
import { CustomHtmlDataProcessorOptions } from '@root/src/client/models/ckeditor/CustomHtmlDataProcessor';
import type { LocalizedContentOptions } from './interfaces';

var CKEditor:any = undefined;
var CKEditorInspector:any = undefined;
if(process.env.VUE_ENV == 'client') {
  CKEditor = require('@ckeditor/ckeditor5-vue2');
  // @ts-ignore
  // CKEditorInspector = require('@ckeditor/ckeditor5-inspector');
}

@Component({
  inheritAttrs: false,
  components : {
    CKEditor : CKEditor ? CKEditor.component : undefined as any
  }
})
export default class CKEditorComponent extends mixins<GenericInput<any>>(GenericInput) {

  @Prop({
    type: [Object,String],
    required: false,
  }) readonly componentClass!: any

  @Prop({
    type: Object,
    required: false,
  }) readonly options?: LocalizedContentOptions

  editorClass:any = null;
  editorInstance:any = null;

  config:any = {};

  editorFocus:boolean = false;

  nbWords:number = 0;
  nbCharacters:number = 0;

  get inputPlaceholder() {
    return (this.placeholder && this.placeholder != "") ? this.placeholder : "";
  }

  created() {
      
  }

  mounted() {
    // We set the config for editor
    var config:any = {
      placeholder: this.inputPlaceholder,
      plugins: [],
      toolbar: {
        items: [],
        shouldNotGroupWhenFull: true
      },
      link: {
        addTargetToExternalLinks: true
      }
    }

    config.plugins.push(Essentials);
    config.plugins.push(Paragraph);

    if(!this.options || !this.options.isTextOnly) {
      config.plugins.push(Heading, FontColor);
      config.toolbar.items.push('heading', 'fontColor');
    }

    config.plugins.push(Bold, Italic, Autoformat);
    config.toolbar.items.push('bold','italic');

    if(!this.options || !this.options.isTextOnly) {
      config.plugins.push(
        Alignment,
        Indent, IndentBlock,
        ListProperties,
        BlockQuote,
        HorizontalLine
      );
      config.toolbar.items.push(
        'alignment',
        'outdent', 'indent',
        'bulletedList', 'numberedList',
        'blockQuote',
        'horizontalLine'
      );
    }

    config.plugins.push(Link, AutoLink);
    config.toolbar.items.push('link');

    config.plugins.push(RemoveFormat);
    config.toolbar.items.push('removeFormat');
    
    if(!this.options || !this.options.isTextOnly) {
      config.plugins.push(MediaEmbed,Image, ImageInsert, AutoImage, ImageResizeEditing, ImageResizeHandles)
      config.toolbar.items.push('insertImage','mediaEmbed'); // 'imageUpload'
      config.mediaEmbed = {
        previewsInData : true
      }

      config.plugins.push(Table, TableToolbar, TableProperties, TableCellProperties);
      config.toolbar.items.push('insertTable');
      config.table = {
            contentToolbar: [
                'tableColumn', 'tableRow', 'mergeTableCells',
                'tableProperties', 'tableCellProperties'
            ],
      }
    }

    if(this.options && this.options.allowSourceEditing) {
      config.plugins.push(SourceEditing);
      config.toolbar.items.push('sourceEditing');
    }
    
    if(this.options && this.options.upload) {
      config.plugins.push(ImageUpload);
      config.plugins.push(CkEditorUploadAdapter);

      
      config.ckEditorUploadAdapter = {
        uploadHandler: (this.options && this.options.upload) ? this.options.upload : undefined,
        app:this
      } as CkEditorUploadAdapterOptions;
    }

    if(this.options && this.options.allowWordCount) {
      config.plugins.push(WordCount);

      config.wordCount = {
          onUpdate: (stats:any) => {
            this.nbWords = stats.words;
            this.nbCharacters = stats.characters;
          }
      }
    }

    config.toolbar.items.push('undo','redo');
    config.customHtmlDataProcessor = {
      removeParagraph: this.options && this.options.isTextOnly
    } as CustomHtmlDataProcessorOptions

    this.config = config;
    this.editorClass = CustomEditor;
  }

  onEditorReady(ckeditor:CustomEditor) {
    if(CKEditorInspector) {
      CKEditorInspector.attach(ckeditor);
    }
    this.editorInstance = ckeditor;

    if(this.options && this.options.isTextOnly) {
      this.preventParagraphCreationOnEnter();
    }

    // We add listener on image upload
    if(this.options && this.options.upload) {
      this.onImageUploaded();
    }
  }

  preventParagraphCreationOnEnter() {
    this.editorInstance.editing.view.document.on( 'enter', ( evt:any, eventData:any ) => {
		  this.editorInstance.execute( 'shiftEnter' );
			eventData.preventDefault();
			evt.stop();
			this.editorInstance.editing.view.scrollToTheSelection();
		}, { priority: 'high' } );
  }

  onImageUploaded() {
    const imageUploadEditing = this.editorInstance.plugins.get( 'ImageUploadEditing' );

    this.editorInstance.model.schema.extend( 'imageBlock', {
      allowAttributes: [ 'uploadPictureID' ]
    } );

    this.editorInstance.conversion.for( 'upcast' ).attributeToAttribute( {
        view: 'upload-picture-id',
        model: 'uploadPictureID'
    } );

    this.editorInstance.conversion.for( 'downcast' ).add( (dispatcher:any) => {
      dispatcher.on( 'attribute:uploadPictureID:imageBlock', ( evt:any, data:any, conversionApi:any ) => {
          if ( !conversionApi.consumable.consume( data.item, evt.name ) ) {
              return;
          }

          const viewWriter = conversionApi.writer;
          const figure = conversionApi.mapper.toViewElement( data.item );
          const img = figure.getChild( 0 );

          if ( data.attributeNewValue !== null ) {
              viewWriter.setAttribute( 'upload-picture-id', data.attributeNewValue, img );
          } else {
              viewWriter.removeAttribute( 'upload-picture-id', img );
          }
      } );
    } );  

    imageUploadEditing.on( 'uploadComplete', ( evt:any, { data, imageElement }:any ) => {
      // data is the result of upload method in CKEditorUploadAdapter
      this.editorInstance.model.change( (writer:any) => {
        if(data.uploadPictureID != undefined) {
          console.log("uploadComplete - uploadPictureID:" + data.uploadPictureID);
          writer.setAttribute( 'uploadPictureID', data.uploadPictureID, imageElement );
        }
      } );
    } );
  }

  toggleEditorFocus(val = !this.editorFocus) {
    setTimeout(() => {
      this.editorFocus = val
    }, 10)
  }

  @Watch('input', { deep: true })
  onInputChange(val: any, oldVal: any) {
    this.$emit('updated', val);
  }
  
}
</script>