import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { useShopAdmin } from "./useShopAdmin";

interface MethodsOfDeliveryAdminInput {
  mode?: "PRODUCT" | "ACTIVITY"
}

export function useMethodsOfDeliveryAdmin(props:MethodsOfDeliveryAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();
  
  const { selectedShop, callShopAdmin } = useShopAdmin(props, {emit})

  // We set the mode for shop products
  var mode = props.mode;
  if(mode == undefined) {
    mode = "PRODUCT";
  }

  var isListLoading:Ref<boolean> = ref(false);

  const pickups:Ref<any[]|null> = ref(null);
  const shippings:Ref<any[]|null> = ref(null);

  const updateMethodsOfDelivery = async () => {

    // We do not have methods of delivery for activities
    if(mode == "ACTIVITY") {
      pickups.value = null;
      shippings.value = null;
      return;
    }

    isListLoading.value = true;
    try {
      var response = await callShopAdmin('/shop/'+selectedShop.value.shop._id+'/methods-of-delivery');
      // We check the additional fields
      if(response.methodsOfDelivery) {
        pickups.value = response.methodsOfDelivery.filter((mod:any) => {
          return mod.type == 'PICKUP'
        });
        
        shippings.value = response.methodsOfDelivery.filter((mod:any) => {
          return mod.type == 'SHIPPING'
        })
      }
      else {
        pickups.value = null;
        shippings.value = null;
      }

    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }
  updateMethodsOfDelivery();

  const updateMethodOfDelivery = async (methodOfDeliveryForm:any) => {
    
    var input = {
      "shopID" : selectedShop.value.shop._id,
      ...methodOfDeliveryForm
    }

    var result:any = { updated: false };

    try {
      var response = await callShopAdmin('/delivery/update', input);
      if(response.updated) {  
        result.updated = true;
        result.delivery = response.delivery;
      }
    }
    catch(error:any) {
      console.log(error);
    }

    return result;
  }

  const removeMethodOfDelivery = async (methodOfDelivery:any) => {
    var input = {
      "shopID" : selectedShop.value.shop._id,
      "code" : methodOfDelivery.code
    }

    isListLoading.value = true;

    try {
      var response = await callShopAdmin('/delivery/remove', input);
      if(response.removed) { 
        isListLoading.value = false;
        return true; 
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return false;
  }

  const getMethodAssociatedProducts = async (methodOfDelivery:any) => {
    isListLoading.value = true;
    var associatedProducts:any[] = [];

    try {
      var response = await callShopAdmin('/shop/'+selectedShop.value.shop._id+'/delivery/'+methodOfDelivery.code+'/products');
      if(response.products) {  
        associatedProducts = response.products;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return associatedProducts;
  }

  const getListEligibleProductsToAdd = async (methodOfDelivery:any) => {

    isListLoading.value = true;
    var eligibleProductsToAdd = [];

    try {
      var response = await callShopAdmin('/shop/'+selectedShop.value.shop._id+'/delivery/'+methodOfDelivery.code+'/eligible-products');
      if(response.products) {  
        eligibleProductsToAdd = response.products;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return eligibleProductsToAdd;
  }

  const getProductsWithMissingTypes = async (shopID?:string) => {

    var productsWithMissingTypes = [];

    try {
      var path = '/shops/products-with-missing-delivery-types';
      if(shopID) {
        path = '/shop/'+shopID+'/delivery/products-with-missing-delivery-types'
      }
      var response = await callShopAdmin(path);
      if(response.products) {  
        productsWithMissingTypes = response.products;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return productsWithMissingTypes;
  }

  const removeProductFromMethod = async (productID:string, methodOfDelivery:any) => {

    isListLoading.value = true;
    
    try {
      var response = await callShopAdmin('/shop/'+selectedShop.value.shop._id+'/delivery/'+methodOfDelivery.code+'/product/'+productID+'/remove');
      if(response.removed) {
        isListLoading.value = false;
        return true;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return false;
  }

  const associateProductToMethod = async (productID:string, methodOfDelivery:any) => {

    isListLoading.value = true;
    
    try {
      var response = await callShopAdmin('/shop/'+selectedShop.value.shop._id+'/delivery/'+methodOfDelivery.code+'/product/'+productID+'/add');
      if(response.added) {
        isListLoading.value = false;
        return true;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return false;
  }

  // We check when there is a new shop selected
  watch(
    () => selectedShop.value.shop._id,
    (val:any, oldVal:any) => {
      // We update the methodsOfDelivery when the selected shop is updated
      updateMethodsOfDelivery();
    },
    { deep: false }
  )

  return {
    isListLoading,
    pickups,
    shippings,
    updateMethodOfDelivery,
    removeMethodOfDelivery,
    getMethodAssociatedProducts,
    getListEligibleProductsToAdd,
    associateProductToMethod,
    removeProductFromMethod,
    updateMethodsOfDelivery,
    getProductsWithMissingTypes
  }
  
}