<template>
  <div class="ibox categories">
    <div class="ibox-title">
      <h2>{{ $t('shop.products.categories.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <p v-if="showNumberOfRows">Number of rows: {{numberOfRows}}</p>
    
      <b-table 
          outlined striped
          :items="categories"
          :fields="listFields"
          ref="listItems">
        <template v-slot:cell(ranking)="row">
          <a href="javascript:void(0)" @click="updateRankingUp(row.item)"><i class="fa fa-sort-up"></i></a>
          <a href="javascript:void(0)" @click="updateRankingDown(row.item)"><i class="fa fa-sort-down"></i></a>
        </template> 
        <template v-slot:cell(select)="row">
          <p-check class="p-default p-curve p-primary" color="success" :value="row.item._id" v-model="listItemsSelected"></p-check>
        </template>        
        <template v-slot:cell(options)="row">
          <span v-if="isCategoryUpdateAllowed">
            <a href="javascript:void(0)" @click="showUpdateModal(row.item)">{{$t('shop.products.categories.options.update')}}</a>
            /
          </span>
          <span v-if="isCategoryRemoveAllowed">
            <a href="javascript:void(0)" @click="confirmRemoveItem(row.item)">{{$t('shop.products.categories.options.delete')}}</a>
          </span>
        </template>
      </b-table>

      <button v-if="isCategoryCreateAllowed" class="btn btn-primary" @click="showCreateItemModal()">{{$t('shop.products.categories.create-button')}}</button>

      <b-modal size="lg" ref="createItemModal" :title="$t('shop.products.categories.create.title')" hide-footer lazy>
        <CreateCategory :mode="mode" v-on:category-created="onItemCreated" />
      </b-modal>

      <b-modal size="lg" ref="updateItemModal" :title="$t('shop.products.categories.update.title', {categoryID: itemToUpdate._id})" hide-footer lazy>
        <UpdateCategory :mode="mode" :category="itemToUpdate" v-on:category-updated="onItemUpdated" />
      </b-modal>

      <b-modal ref="removeItemModal" 
          hide-header
          @ok="removeItem">
        {{$t('shop.products.categories.delete-confirmation', {categoryID: itemToRemove._id, categoryTitle: itemToRemove.title})}}
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
  
</style>



<script lang="ts">
import CreateCategory from './Create.vue';
import UpdateCategory from './Update.vue';
import * as api from '@fwk-client/utils/api';

import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, ComputedRef } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { languagesTypes } from '@fwk-client/store/types';
import { formatDate } from '@igotweb-node-api-utils/formatter';

import { useShopAdmin } from '../../../../composables/useShopAdmin';
import { useCategoryAdmin } from '../../../../composables/useCategoryAdmin';

export default defineComponent({
  props: {
    mode: {
      type: String as PropType<"PRODUCT"|"ACTIVITY">,
      default: "PRODUCT"
    }
  },
  components: {
    CreateCategory,
    UpdateCategory
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { shops, selectedShop } = useShopAdmin(props, context);
    const { categories, removeCategory, updateRanking, isCategoryUpdateAllowed,
    isCategoryCreateAllowed,
    isCategoryRemoveAllowed, isListLoading }  = useCategoryAdmin(props, context);

    const mode = props.mode;

    const createItemModal:Ref<HTMLElement|null> = ref(null);
    const updateItemModal:Ref<HTMLElement|null> = ref(null);
    const removeItemModal:Ref<HTMLElement|null> = ref(null);

    const isMultiSelectAllowed:Ref<boolean> = ref(false);
    const showNumberOfRows:Ref<boolean> = ref(false);

    const numberOfRows:Ref<number> = computed(() => {
        return categories.value.length
    })

    const itemToRemove:Ref<any> = ref({});
    const itemToUpdate:Ref<any> = ref({});

    const listLoading:Ref<boolean> = computed(() => {
      return isListLoading.value;
    })

    const listItemsSelected:Ref<any[]> = ref([]);


    const listFields:Ref<any[]> = ref([
      {
        key: "select",
        label: "",
        class: (isMultiSelectAllowed.value ? "" : "hidden"),
      },
      {
        key: "ranking",
        label: "",
      },
      {
        key: "title",
        label: ""
      },
      {
        key: "options"
      }
    ]);

    onMounted(() => {
      updateTableLabels();
    })

    const updateTableLabels = () => {
      listFields.value[2].label = app.$t('shop.products.categories.headers.title') as string;
    }

    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const onItemCreated = (item:any) => {
      // @ts-ignore
      createItemModal.value.hide()
    }

    const onItemUpdated = (item:any) => {
      itemToUpdate.value = item;
    }

    const confirmRemoveItem = (item:any) => {
      itemToRemove.value = item;
      // @ts-ignore
      removeItemModal.value.show()
    }

    const showUpdateModal = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      updateItemModal.value.show()
    }

    const updateRankingUp = (item:any) => {
      updateCategoryRanking(item, "up");
    }

    const updateRankingDown = (item:any) => {
      updateCategoryRanking(item, "down");
    }

    const showCreateItemModal = () => {
      // @ts-ignore
      createItemModal.value.show()
    }

    const removeItem = () => {
      removeCategory(itemToRemove.value._id).then((response:any) => {
        // We reset the user to remove
        itemToRemove.value = {};
      });
    }

    const updateCategoryRanking = (category:any, direction:string) => {
      updateRanking(category._id, direction).then((response:any) => {
        if(response.updated) {  
          var category = response.category;
          // We emit event as user is updated
          onItemUpdated(category);
        }
      });
    }

    watch(
      currentLanguageCode,
      (val:any, oldVal:any) => {
       updateTableLabels();
      },
      { deep: false }
    )

    return { 
      shops, 
      mode,
      selectedShop, 
      listFields,
      categories, 
      removeCategory, 
      updateRanking, 
      isCategoryUpdateAllowed,
      isCategoryCreateAllowed,
      isCategoryRemoveAllowed,
      createItemModal,
      itemToUpdate,
      updateItemModal,
      itemToRemove,
      removeItemModal,
      removeItem,
      showCreateItemModal,
      updateRankingDown,
      updateRankingUp,
      showUpdateModal,
      confirmRemoveItem,
      onItemCreated,
      onItemUpdated,
      listItemsSelected,
      listLoading,
      numberOfRows,
      showNumberOfRows
    }
  }
})
</script>