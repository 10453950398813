<template>
  <validation-provider ref="validator" :name="id" :rules="validationRule" mode="eager" v-slot="{ errors, classes }" slim>
    <div :class="{...rowCSSClass, ...classes, 'listPictures':true}" ref="listPictures">
      <label :for="id" :class="{...labelCSSClass}">{{ inputLabel }} <small v-if="required">*</small></label>
      <div :class="{...fieldCSSClass}">
        
        <b-table 
              outlined striped
              show-empty
              :empty-text="$t('listPictures.empty')"
              :items="picturesToDisplay"
              :fields="listPicturesFields"
              ref="listPictures"
              class="pictures">
            <template v-slot:cell(ranking)="row">
              <a v-if="row.index > 0 && !row.item.isInBrowser" href="javascript:void(0)" @click="updateRankingUp(row.index)"><i class="fa fa-sort-up"></i></a>
              <a v-if="row.index < (picturesToDisplay.length - 1) && !row.item.isInBrowser" href="javascript:void(0)" @click="updateRankingDown(row.index)"><i class="fa fa-sort-down"></i></a>
            </template> 
            <template v-slot:cell(thumbnail)="row">
              <!-- we display the thumbnail -->
              <img :src="row.item.thumbnail.publicUrl">
            </template>
            <template v-slot:cell(options)="row">
              <span v-if="row.item.isInBrowser && optionIsInBrowserLabel!=''">{{ optionIsInBrowserLabel }}</span>
              <button type="button" v-if="!row.item.isInBrowser" class="btn btn-default" @click="copyURLToClipboard(row.item)" >{{$t('listPictures.options.copyURL')}}</button>
              <button type="button" v-if="allowRemove" class="btn btn-default" @click="confirmRemovePicture(row.item)" >{{$t('listPictures.options.delete')}}</button>
            </template>
          </b-table>
          <div v-for="(pictureInput, index) in picturesForm" :key="index" class="input-group">
            <b-form-file
              v-model="picturesForm[index]"
              :placeholder="$t('listPictures.upload_placeholder')"
              :drop-placeholder="$t('listPictures.upload_drop-placeholder')"
            />
            <span class="input-group-append" v-if="!allowUpload">
              <button type="button" class="btn btn-primary removePicture" @click="removePictureInput($event, index)">{{ $t('listPictures.remove-input-button') }}</button>
            </span>
          </div>
          <button v-if="allowUpload" class="btn btn-primary ladda-button picture-upload" data-style="zoom-in" type="button" @click="uploadPicture">{{$t('listPictures.upload-button')}}</button>
          <button v-else class="btn btn-primary picture-add" type="button" @click="addPictureInput">{{$t('listPictures.add-input-button')}}</button>

        <span :class="{...controlCSSClass}" v-if="errors.length > 0">{{ errors[0] }}</span>

        <b-modal ref="removePictureModal" 
          hide-header
          @ok="removePicture">
        {{ $t('listPictures.delete-confirmation') }}
      </b-modal>
      </div>
    </div>
  </validation-provider>
</template>

<style>

.listPictures table.pictures th {
    width:50%;
  }

  .listPictures table.pictures th.ranking {
    width:15px;
  }

  .listPictures table.pictures td.thumbnail {
    text-align: center;
  }

  .listPictures table.pictures td.thumbnail img {
    max-width: 400px;
    max-height: 200px;
  }

  .listPictures table.pictures td.options button {
    display:block;
  }

  .listPictures table.pictures td.ranking a {
    display: block;
  }

  .listPictures .picture-upload,
  .listPictures .picture-add {
    margin-top: 5px;
  }

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericInput from '@fwk-client/components/mixins/GenericInput.vue';
import { extend } from "@fwk-node-modules/vee-validate";
import {generateBase64SrcFromFormFile} from '@fwk-client/utils/browser';
import * as Ladda from 'ladda';
import { UploadPictureResult } from './content/interfaces';

export interface ListPicturesOptions {
  upload? : (picture:File) => Promise<{uploaded:boolean, picture?:UploadPictureResult}>,
  remove? : Function,
  updatePicturesRanking? : Function,
  labels? : {
    isInBrowser? : string
  }
}

@Component({
  components : {
  
  }
})
export default class ListPictures extends mixins<GenericInput<any>>(GenericInput) {

  @Prop({
    type: Object,
    required: false,
  }) readonly options?: ListPicturesOptions

  @Prop({
    type: Array,
    required: false,
    default: () => [],
  }) readonly listPictures!: any[] // The pictures already available in storage and DB
  

  picturesForm:any = [
    null
  ];
  
  picturesToDisplay:any[] = [];

  pictureToRemove:any = {};

  listPicturesFields = [
      {
        key: "ranking",
        class: "ranking",
        label: ""
      },
      {
        key: "thumbnail",
        label: "",
        class: "thumbnail"
      },
      {
        key: "options",
        class: "options",
        label: "",
      }
    ];

  laddaUploadPicture:Ladda.LaddaButton|null = null;

  get inputLabel() {
    return (this.label && this.label != "") ? this.label : "";
  }

  get inputPlaceholder() {
    return (this.placeholder && this.placeholder != "") ? this.placeholder : "";
  }

  get optionIsInBrowserLabel() {
    if(this.options && this.options.labels && this.options.labels.isInBrowser) {
      return this.options.labels.isInBrowser;
    }
    return ""
  }

  get allowUpload() {
    return this.options && this.options.upload;
  }

  get allowRemove() {
    return this.options && this.options.remove;
  }

  get allowRanking() {
    return !this.allowUpload || (this.options && this.options.updatePicturesRanking);
  }


  generatePictureWithThumbnailFromFile(formFile:any, boundBox:any):Promise<any> {
    return generateBase64SrcFromFormFile(formFile).then((src:string) => {
      return this.generatePictureWithThumbnailFromURL(src, formFile.type, boundBox).then((picture) => {
        return {
          ...picture,
          isInBrowser: true
        }
      })
    })    
  }

  getThumbnailSize(boundBox:any, pictureSize:any) {
    var scaleRatio = Math.min(...boundBox) / Math.max(pictureSize.w, pictureSize.h)
    let w = pictureSize.w*scaleRatio
    let h = pictureSize.h*scaleRatio
    return {
      w,
      h
    }
  }

  generatePictureWithThumbnailFromURL(url:string, type:string, boundBox:any):Promise<any> {
    if (!boundBox || boundBox.length != 2){
      throw "You need to give the boundBox"
    }
    
    var canvas = document.createElement("canvas")
    var ctx = canvas.getContext('2d');

    return new Promise((resolve, reject) => {
    
      var img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = () => {
          var thumbnailSize = this.getThumbnailSize(boundBox, {
            h: img.height,
            w: img.width
          })
          canvas.width = thumbnailSize.w;
          canvas.height = thumbnailSize.h;
          ctx!.drawImage(img, 0, 0, thumbnailSize.w, thumbnailSize.h);
          var thumbnailURL = canvas.toDataURL(type);
          return resolve({
            original: {
              publicUrl : url
            },
            thumbnail: {
              publicUrl : thumbnailURL
            },
            originalSize: {
              h: img.height,
              w: img.width
            },
            thumbnailSize: {
              h: canvas.height,
              w: canvas.width
            }
          })
      }
      img.src = url;
      img.onerror = function(evt) {
        reject("Error while loading image: "+url);
      }
    });
  }

  updatePicturesToDisplay() {
    var promises:Promise<any>[] = [];

    // We get the pictures from listPictures
    for(var picture of this.listPictures) {
      if(picture) {
        if(!picture.thumbnail) {
          var resultPicture = {
            ...picture
          }
          // We need to generate the thumbnail in browser
          // In case the URL in parameter is a path
          var pictureUrl = this.getStaticURLFromPath(picture.original.path);
          var promise = this.generatePictureWithThumbnailFromURL(pictureUrl, picture.original.mime, [400, 200])
            .then((pictureWithThumbnail) => {
              return {
                ...resultPicture,
                ...pictureWithThumbnail
              }
            })
            .catch((error) => {
              // In case we are not able to generate the thumbnail, we use the picture as thumbnail
              return {
                ...resultPicture,
                thumbnail: {
                  publicUrl :  pictureUrl
                }
              };
            });
          promises.push(promise);
        }
        else {
          // We set the public URL in the thumbnail structure
          var pictureWithThumbnail = {
            ...picture,
            thumbnail : {
              ...picture.thumbnail,
              publicUrl :  this.getStaticURLFromPath(picture.thumbnail.path)
            }
          }
          promises.push(Promise.resolve(pictureWithThumbnail))
        }
      }
    }

    // We get the pictures from Form
    if(!this.allowUpload) {
      for(var file of this.picturesForm) {
        if(file) {
          var promise = this.generatePictureWithThumbnailFromFile(file, [400, 200]);
          promises.push(promise);
        }
      }
    }

    Promise.all(promises).then((results) => {
      this.picturesToDisplay = results;
    }).catch((error:any) => {
    });
  }

  created() {
    if(!this.value || this.value === "") {
      this.setDefaultInput();
    }
    if(this.listPictures.length > 0) {
      this.updatePicturesToDisplay();
    }
    this.addValidation();
  }

  mounted() {
    var uploadPictureButton:HTMLButtonElement|null = (this.$refs.listPictures as HTMLElement).querySelector( 'button.picture-upload' );
    if(uploadPictureButton) {
      this.laddaUploadPicture = Ladda.create(uploadPictureButton!);
    }

    this.listPicturesFields[1].label = this.$t('listPictures.headers.thumbnail') as string;
    this.listPicturesFields[2].label = this.$t('listPictures.headers.options') as string;
  }

  validate() {
    // @ts-ignore
    return this.$refs.validator.validate();
  }

  setDefaultInput() {
    this.input = [];
  }

  get validationRule() {
    var validation:any = {}
    if(this.required) {
      validation = {
        ...validation,
        "listPicturesRequired" : true,
        "required" : true
      }
    }
    return validation;
  }

  addValidation() {
    var componentInstance = this;
    extend('listPicturesRequired',{
      params: [],
      validate(content, params):Promise<boolean|string> {

        if(componentInstance.input && componentInstance.input.length > 0) {
          return Promise.resolve(true);
        }
        return Promise.resolve("A picture is required");
      }
    });
  }

  uploadPicture(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    if(!this.allowUpload && this.picturesForm != null && this.picturesForm[0] != null) {
      return;
    }

    this.laddaUploadPicture!.start();
    this.options!.upload!.call(undefined, this.picturesForm[0]).then((result:any) => {
      if(result.uploaded) {
        // We empty the form so that picture in form is not added in the table
        this.picturesForm = [null];
        // We update the list of pictures
        this.$emit('update:listPictures', [
          ...this.listPictures,
          result.picture
        ]);
      }
      this.laddaUploadPicture!.stop();
    });
  }

  addPictureInput(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();
    // We add a picture input field
    this.picturesForm.push(null);
  }

  removePictureInput(evt:Event, index:number) {
    // We prevent submit of the page
    evt.preventDefault();
    if(this.picturesForm.length > 1) {
      Vue.delete(this.picturesForm, index);
    }
    else if(this.picturesForm.length == 1 && index == 0) {
      // We just reset the value if there is one field
      Vue.set(this.picturesForm,0,null);
    }
  }

  updateRankingUp(index:number) {
    if(!this.allowUpload) {
      var updatedPictures = [...this.input];
      var tmp = updatedPictures[index];
      updatedPictures[index] = updatedPictures[index - 1];
      updatedPictures[index - 1] = tmp;
      this.input = updatedPictures;
    }
    else {
      this.options!.updatePicturesRanking!.call(undefined, index, "up").then((result:any) => {
        if(result.updated) {
          this.$emit('update:listPictures', result.pictures);
        }
      });
    }
  }

  updateRankingDown(index:number) {
    if(!this.allowUpload) {
      var updatedPictures = [...this.input];
      var tmp = updatedPictures[index];
      updatedPictures[index] = updatedPictures[index + 1];
      updatedPictures[index + 1] = tmp;
      this.input = updatedPictures;
    }
    else {
      this.options!.updatePicturesRanking!.call(undefined, index, "down").then((result:any) => {
        if(result.updated) {
          this.$emit('update:listPictures', result.pictures);
        }
      });
    }
  }

  copyURLToClipboard(item:any) {
    var url = item.original.publicUrl;
    if(item.original.path) {
      url = this.getStaticURLFromPath(item.original.path);
    }
    navigator.clipboard.writeText(url);
  }

  confirmRemovePicture(item:any) {
    this.pictureToRemove = item;
    // @ts-ignore
    this.$refs.removePictureModal.show()
  }

  removePicture(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    if(!this.allowRemove) {
      return;
    }

    this.options!.remove!.call(undefined, this.pictureToRemove._id).then((result:any) => {
      if(result.removed) {
        // We reset the picture to remove
        this.pictureToRemove = {};
        // @ts-ignore
        this.$refs.removePictureModal.hide()
        // We update the list of pictures
        this.$emit('update:listPictures', result.pictures);
      }
    });
  }

  @Watch('listPictures', { deep: true })
  onListPicturesChange(val:any, oldVal:any) {
    this.updatePicturesToDisplay();
  }

  @Watch('picturesForm', { deep: true })
  onPicturesFormChange(val: any, oldVal:any) {
    if(!this.allowUpload) {
      this.updatePicturesToDisplay();
      this.input = val;
    }
  }

  @Watch('value')
  onValueChange(val: any, oldVal: any) {
    if(!val || val === "") {
      this.setDefaultInput();
    }
    else {
      this.input = val;
    }
    // @ts-ignore
    this.$refs.validator.validate(val);
  }

  @Watch('input', { deep: true })
  onInputChange(val: any, oldVal: any) {
    // We found a non empty value
    return this.$emit('update:value', val);
  }
  
}
</script>