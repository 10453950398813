import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { types as applicationTypes } from '@root/src/client/store/application';
import { useShopAdmin } from "./useShopAdmin";

interface CategoryAdminInput {
  mode?: "PRODUCT" | "ACTIVITY"
}

export function useCategoryAdmin(props:CategoryAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();

  // We set the mode for shop products
  var mode = props.mode;
  if(mode == undefined) {
    mode = "PRODUCT";
  }
  
  const { selectedShop, callShopAdmin } = useShopAdmin(props, {emit})

  var isListLoading:Ref<boolean> = ref(false);

  const isCategoryUpdateAllowed:Ref<boolean> = ref(false);
  const isCategoryCreateAllowed:Ref<boolean> = ref(false);
  const isCategoryRemoveAllowed:Ref<boolean> = ref(false);

  const updateListCategories = async () => {

    isListLoading.value = true;
    try {
      var input = {
        mode : mode
      }
      var response = await callShopAdmin('/shop/'+selectedShop.value.shop._id+'/categories', input);
      var mutation = mode == "PRODUCT" ? applicationTypes.mutations.SET_USER_PRODUCTS_CATEGORIES : applicationTypes.mutations.SET_USER_ACTIVITIES_CATEGORIES;
      store.commit('application/'+mutation, response.categories ? response.categories : []);
      if(response.options) {
        isCategoryUpdateAllowed.value = response.options.isUpdateAllowed;
        isCategoryCreateAllowed.value = response.options.isCreateAllowed;
        isCategoryRemoveAllowed.value = response.options.isRemoveAllowed;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }
  updateListCategories();

  const categories = computed(() => {
    var getter = mode == "PRODUCT" ? applicationTypes.getters.GET_USER_PRODUCTS_CATEGORIES : applicationTypes.getters.GET_USER_ACTIVITIES_CATEGORIES;
    return store.getters['application/'+getter];
  })

  // We check when there is a new shop selected
  watch(
    () => selectedShop.value.shop._id,
    (val:any, oldVal:any) => {
      // We update the categories when the selected shop is updated
      updateListCategories();
    },
    { deep: false }
  )

  const categoryForm:any = reactive({
    title: ''
  });

  const updateCategoryFormForUpdate = (category:any) => {
    categoryForm.categoryID = (category && category._id) ? category._id : undefined,
    categoryForm.title =  category.title
  }

  const createCategory = async () => {
    var result:any = {
      created: false
    }

    var input = {
      "shopID" : selectedShop.value.shop._id,
      "title" : categoryForm.title,
      "mode" : mode,
    }

    isListLoading.value = true;
    try {
      var response = await callShopAdmin('/category/create', input);
      isListLoading.value = false;
      if(response.created) {  
        // We reset the field
        categoryForm.title = "";

        // We update the list
        updateListCategories();

        result.created = true;
        result.category = response.category;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const updateCategory = async () => {

    var result:any = {
      updated: false
    }

    var input:any = {
      "categoryID" : categoryForm.categoryID,
      "title" : categoryForm.title
    }

    isListLoading.value = true;
    try {
      var response = await callShopAdmin('/category/'+categoryForm.categoryID+'/update', input);
      isListLoading.value = false;
      if(response.updated) {  
        // We update the form based on values returned by backend (in case not updated)
        categoryForm.title = response.category.title;

        // We update the list
        updateListCategories();

        result.updated = true;
        result.category = response.category;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const removeCategory = async (categoryID:string) => {

    var input = {
      "categoryID" : categoryID,
    }

    isListLoading.value = true;

    try {
      var response = await callShopAdmin('/category/'+categoryID+'/remove', input);
      isListLoading.value = false;

      // We update the list
      updateListCategories();

      return response;
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return false;
  }

  const updateRanking = async(categoryID:string, direction:string) => {

    var input = {
      "direction" : direction,
    }

    isListLoading.value = true;
    try {
      var response = await callShopAdmin('/category/'+categoryID+'/update-ranking', input);
      if(response.updated) {
        await updateListCategories();
      }
      isListLoading.value = false;
      return response;
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return false;
  }

  const getCategoryFromID = (categoryID:string) => {
    var filtered = categories.value.filter((category:any) => {
      return category._id == categoryID
    });
    if(filtered.length == 1) {
      return filtered[0];
    }
    return undefined;
  }

  return {
    isListLoading,
    categories,
    categoryForm,
    updateCategoryFormForUpdate,
    createCategory,
    updateCategory,
    removeCategory,
    updateRanking,
    isCategoryUpdateAllowed,
    isCategoryCreateAllowed,
    isCategoryRemoveAllowed,
    getCategoryFromID
  }
  
}