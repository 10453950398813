import HtmlDataProcessor from '@ckeditor/ckeditor5-engine/src/dataprocessor/htmldataprocessor'

export interface CustomHtmlDataProcessorOptions {
    removeParagraph?: boolean;
}

export class CustomHtmlDataProcessor extends HtmlDataProcessor {

    private removeParagraph:boolean;

    constructor(document:any, config?:CustomHtmlDataProcessorOptions) {
        super(document);

        if(config && config.removeParagraph) { this.removeParagraph = config.removeParagraph; }
        else { this.removeParagraph = false; }
    }

    toData( viewFragment:any ) {
        var result = super.toData( viewFragment );

        if(this.removeParagraph) {
            // We remove the paragraphe tags
            result = result.replace(/^<p>/,"").replace(/<\/p>$/,"");
        }

        return result;
    }

}