<template>
  <form role="form" @submit="onFormSubmit" name="product">
    <div class="product-form">
      <div class="row" v-if="productForm.title">
        <div class="col-lg-12">
          <h2>{{productForm.title}}</h2>
        </div>
      </div>
      <div class="row" v-if="allowCreateFrom">
        <div class="col-lg-12">
          <h3>{{$t('shop.products.'+labelsKey+'.create-from.title')}}</h3>
        </div>
      </div>
      <Select 
        v-if="allowCreateFrom"
        :selectOptions="{
          options: products,
          getOptionLabel : option => option.title + (option.subtitle ? ' - '+option.subtitle: '') + (option.year ? ' ('+option.year+')': ''),
          reduce : product => product
        }"
        v-bind:value.sync="createFromForm.product" 
        id="createFromProduct" 
        :label="$t('shop.products.'+labelsKey+'.create-from.label')" 
        :placeholder="$t('shop.products.'+labelsKey+'.create-from.placeholder')" 
        labelAsColumn 
      >
        <template v-slot:buttons>
            <button class="btn btn-primary create-from" type="button" @click="createFromProduct()">{{ $t('shop.products.'+labelsKey+'.create-from.button') }}</button>
        </template>
      </Select>
      <div class="row">
        <div class="col-lg-12">
          <h3>{{$t('shop.info.'+labelKey+'.information')}}</h3>
        </div>
      </div>
      <TextField 
        ref="updateTitle"
        v-bind:value.sync="productForm.title" 
        id="updateTitle" 
        :label="$t('shop.info.'+labelKey+'.title')" 
        :placeholder="$t('shop.info.'+labelKey+'.title_placeholder')" 
        :labelAsColumn="true"
        :required="true"
      />
      <div class="form-group row">
        <label for="updateSubtitle" class="col-lg-3 col-form-label">{{$t('shop.info.'+labelKey+'.subtitle')}}</label>
        <div class="col-lg-9">
          <input id="updateSubtitle" type="text" :placeholder="$t('shop.info.'+labelKey+'.subtitle_placeholder')" class="form-control" v-model="productForm.subtitle" />
        </div>
      </div>
      <div class="form-group row">
        <label for="updateReference" class="col-lg-3 col-form-label">{{$t('shop.info.'+labelKey+'.reference')}}</label>
        <div class="col-lg-9">
          <input id="updateReference" type="text" :placeholder="$t('shop.info.'+labelKey+'.reference_placeholder')" class="form-control" v-model="productForm.reference" />
        </div>
      </div>
      <div class="form-group row">
          <label for="category" class="col-lg-3 col-form-label">{{$t('shop.info.'+labelKey+'.category')}}</label>
          <div class="col-lg-9">
            <div v-if="isCategoryListLoading" class="sk-spinner sk-spinner sk-spinner-three-bounce">
                <div class="sk-bounce1"></div>
                <div class="sk-bounce2"></div>
                <div class="sk-bounce3"></div>
            </div>
            <v-select v-if="categories.length > 0" id="category" :placeholder="$t('shop.info.'+labelKey+'.category_placeholder')" v-model="productForm.category" :options="categories" label="title"></v-select>
            <span v-if="!isCategoryListLoading && categories.length==0">{{ $t('shop.info.'+labelKey+'.noCategory') }}</span>
          </div>
      </div>
      <Select 
        v-if="allowedProductTypes.length > 1"
        :selectOptions="{
          options: allowedProductTypes,
          getOptionLabel : option => $t('shop.products.'+labelsKey+'.types.'+option),
        }"
        v-bind:value.sync="productForm.type" 
        id="type" 
        :label="$t('shop.info.'+labelKey+'.type')" 
        :placeholder="$t('shop.info.'+labelKey+'.type_placeholder')" 
        required 
        labelAsColumn 
      />
      <SwitchCheck 
            ref="updateIsActivated"
            v-bind:value.sync="productForm.isActivated" 
            id="updateIsActivated" 
            :label="$t('shop.info.'+labelKey+'.isActivated')" 
            :labelAsColumn="true"
        />
      <LocalizedContent 
        ref="description"
        v-bind:value.sync="productForm.description" 
        id="updateDescription" 
        :label="$t('shop.info.'+labelKey+'.description')" 
        :placeholder="$t('shop.info.'+labelKey+'.description_placeholder')" 
        :labelAsColumn="true"
        :required="false"
        :languageCodes="availableLanguageCodes"
        :rowClass="{'form-group':true, 'row':true, 'update-description':true}"
        :options="getLocalizedContentOptions()" 
      />
      <div class="form-group row">
          <label for="relatedProducts" class="col-lg-3 col-form-label">{{$t('shop.info.'+labelKey+'.relatedProducts')}}</label>
          <div class="col-lg-9">
            <span>{{ $t('shop.info.'+labelKey+'.noRelatedProductNote') }}</span>
            <div v-if="isProductListLoading" class="sk-spinner sk-spinner sk-spinner-three-bounce">
                <div class="sk-bounce1"></div>
                <div class="sk-bounce2"></div>
                <div class="sk-bounce3"></div>
            </div>
            <v-select v-if="products.length > 0" id="relatedProducts" :placeholder="$t('shop.info.'+labelKey+'.relatedProducts_placeholder')" multiple v-model="productForm.relatedProductsIDs" :options="products" :getOptionLabel="getProductTitle" :reduce="product => product._id"></v-select>
          </div>
      </div>
      <div class="hr-line-dashed"></div>
      <div class="row">
        <div class="col-lg-12">
          <h3>{{$t('shop.info.'+labelKey+'.pictures')}}</h3>
        </div>
      </div>

      <ListPictures 
        v-bind:listPictures.sync="productPictures"
        v-bind:value.sync="productForm.pictures"
        :options="getListPicturesOptions()"
        id="listPictures" 
        :label="$t('shop.info.'+labelKey+'.pictures')" 
        labelAsColumn 
      />

      <div class="hr-line-dashed"></div>
      <div class="row">
        <div class="col-lg-12">
          <h3>{{$t('shop.info.'+labelKey+'.price.label')}}</h3>
        </div>
      </div>
      <div class="form-group row">
        <label for="createPriceAmount" class="col-lg-3 col-form-label">{{$t('shop.info.'+labelKey+'.price.amount')}}</label>
        <div class="col-lg-9">
          <input id="createPriceAmount" type="text" :placeholder="$t('shop.info.'+labelKey+'.price.amount_placeholder')" class="form-control" v-model="productForm.price.amount" required />
        </div>
      </div>
      <div class="form-group row">
        <label for="createPriceNonDiscountedAmount" class="col-lg-3 col-form-label">{{$t('shop.info.'+labelKey+'.price.non-discounted-amount')}}</label>
        <div class="col-lg-9">
          <input id="createPriceNonDiscountedAmount" type="text" :placeholder="$t('shop.info.'+labelKey+'.price.non-discounted-amount_placeholder')" class="form-control" v-model="productForm.price.nonDiscountedAmount" />
        </div>
      </div>
      <div class="form-group row">
        <label for="createPriceCurrencyCode" class="col-lg-3 col-form-label">{{$t('shop.info.'+labelKey+'.price.currency')}}</label>
        <div class="col-lg-9">
          <input id="createPriceCurrencyCode" type="text" :placeholder="$t('shop.info.'+labelKey+'.price.currency_placeholder')" class="form-control" v-model="productForm.price.currencyCode" />
        </div>
      </div>

      <div v-if="(pickups != null && pickups.length > 0) || (shippings != null && shippings.length > 0)" >
        <div class="hr-line-dashed"></div>
        <div class="row">
          <div class="col-lg-12">
            <h3>{{$t('shop.info.'+labelKey+'.methodsOfDelivery')}}</h3>
          </div>
        </div>
        <Select 
          v-if="pickups.length > 0"
          :selectOptions="{
            options: pickups,
            getOptionLabel : option => option.name,
            reduce: option => option.code
          }"
          v-bind:value.sync="methodsOfDeliveryForm.pickup" 
          id="pickups" 
          :label="$t('shop.settings.methodsOfDelivery.pickup.title')" 
          :placeholder="$t('shop.info.'+labelKey+'.type_placeholder')" 
          labelAsColumn 
        />
        <Select 
          v-if="shippings.length > 0"
          :selectOptions="{
            options: shippings,
            getOptionLabel : option => option.name,
            reduce: option => option.code
          }"
          v-bind:value.sync="methodsOfDeliveryForm.shipping" 
          id="shippings" 
          :label="$t('shop.settings.methodsOfDelivery.shipping.title')" 
          :placeholder="$t('shop.info.'+labelKey+'.type_placeholder')" 
          labelAsColumn 
        />
      </div>

      <div v-if="productForm.type == 'WINE'" class="hr-line-dashed"></div>
      <div v-if="productForm.type == 'WINE'">
        <WineForm v-bind:value.sync="wineForm" :product="productInput" />
      </div>

      <div v-if="mode == 'ACTIVITY'" class="hr-line-dashed"></div>
      <div v-if="mode == 'ACTIVITY'">
        <ActivityForm v-bind:value.sync="activityForm" :product="productInput" />
      </div>
    </div>
    <button class="btn btn-primary ladda-button product" data-style="zoom-in" type="submit">{{buttonLabel}}</button>
  </form>
</template>

<style>
  .product-form .update-description textarea {
    min-height: 100px;
  }
</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';

import { roles as apiRoles } from '@igotweb/core-api/src/roles';
import { authenticationTypes } from '@fwk-client/store/types';
import WineForm from './WineForm.vue';
import ActivityForm from './ActivityForm.vue';
import LocalizedContent from '@root/src/client/components/panels/input/LocalizedContent.vue';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';
import ListPictures from '@root/src/client/components/panels/input/ListPictures.vue';
import Select from '@fwk-client/components/panels/input/Select.vue';
import TextField from '@fwk-client/components/panels/input/TextField.vue';

import type { LocalizedContentOptions } from '@root/src/client/components/panels/input/content/interfaces';

import { useCategoryAdmin } from '../../../../composables/useCategoryAdmin';
import { useProductAdmin } from '../../../../composables/useProductAdmin';
import { useMethodsOfDeliveryAdmin } from '../../../../composables/useMethodsOfDeliveryAdmin';

export default defineComponent({
  props: {
    mode: {
      type: String as PropType<"PRODUCT"|"ACTIVITY">,
      default: "PRODUCT"
    },
    product: {
      type: Object as PropType<any>,
      required: false
    }
  },
  components: {
    WineForm,
    ActivityForm,
    LocalizedContent,
    ListPictures,
    SwitchCheck,
    Select,
    TextField
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { categories, isListLoading: isCategoryListLoading } = useCategoryAdmin(props, context);
    const { products, uploadPicture, removePicture, productForm, updateProductFormForUpdate, createProduct, updateProduct, isListLoading: isProductListLoading, allowedProductTypes  } = useProductAdmin(props, context);
    const { pickups, shippings } = useMethodsOfDeliveryAdmin(props, context);

    const availableLanguageCodes = ['fr','en'];

    const mode = props.mode;
    const labelKey = (mode == "PRODUCT" ? "product" : "activity");
    const labelsKey = (mode == "PRODUCT" ? "products" : "activities");

    const wineForm:Ref<any> = ref({});
    const activityForm:Ref<any> = ref({});
    const createFromForm:Ref<any> = ref({});
    
    const methodsOfDeliveryForm:Ref<any> = ref({
      pickup:null,
      shipping:null
    })

    const { product } = toRefs(props);

    const productPictures:Ref<any[]> = ref(props.product ? props.product.pictures : []);

    const buttonLabel = computed(() => {
      if(product.value) { return app.$t('shop.update.button') }
      else { return 'Create'; }
    })

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var button:HTMLButtonElement|null = document.querySelector( 'form[name=product] button.ladda-button.product' );
      laddaSubmit = Ladda.create(button!);
    })

    const allowCreateFrom = props.product == undefined;

    const createFromProduct = () => {
      if(createFromForm.value.product) {
          updateProductAndExtraFormForUpdate(createFromForm.value.product);
      }
    }

    const productInput:Ref<any> = ref(undefined)
    const updateProductAndExtraFormForUpdate = (product:any) => {
      // We update the product form
      updateProductFormForUpdate(product);
      // We need to update extra
      productInput.value = product;
    }

    if(props.product) {
      updateProductAndExtraFormForUpdate(props.product);
    }

    const getLocalizedContentOptions = () => {
      var allowSourceEditing = $store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE](apiRoles.superadmin);
      var options:LocalizedContentOptions = {
        isTextOnly : true,
        allowWordCount : true,
        allowSourceEditing : allowSourceEditing
      }
      return options;
    }

    const getListPicturesOptions = () => {
      var options:any = {};
      if(product.value) {
        options = {
          upload : (picture:File) => {
            return uploadPicture(product.value._id, picture);
          },
          remove : (pictureID:string) => {
            return removePicture(product.value._id, pictureID);
          }
        }
      }
      else {
        options = {
          labels : {
            isInBrowser : app.$t('shop.products.'+labelsKey+'.create.picturesInBrowser') as string
          }
        }
      }
      return options;
    }

    const getProductTitle = (product:any) => {
      return app.$shop.product.getProductTitle(product);
    }

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();

      // We update the product form
      var deliveryCodes = [];
      if(methodsOfDeliveryForm.value.pickup) {
        deliveryCodes.push(methodsOfDeliveryForm.value.pickup);
      }
      if(methodsOfDeliveryForm.value.shipping != null) {
        deliveryCodes.push(methodsOfDeliveryForm.value.shipping);
      }
      if(deliveryCodes.length > 0) {
        productForm.methodsOfDeliveryCodes = deliveryCodes;
      }

      var extraForm = {
        ...wineForm.value,
        ...activityForm.value
      }

      if(product.value) {
        updateProduct(extraForm).then((result:any) => {
          if(result.updated) {
            context.emit('product-updated', result.product);
          }
          laddaSubmit!.stop();
        })
      }
      else {
        createProduct(extraForm).then((result:any) => {
          if(result.created) {
            context.emit('product-created', result.product);
          }
          laddaSubmit!.stop();
        })        
      }
    }

    const onPicturesUpdated = (pictures:any[]) => {
      context.emit('product-updated', {
        ...product.value,
        pictures : pictures
      });
    }

    const updateMethodsOfDeliveryFormForUpdate = () => {
      if(productForm.methodsOfDeliveryCodes && productForm.methodsOfDeliveryCodes.length > 0) {
        for(var deliveryCode of productForm.methodsOfDeliveryCodes) {
          // We find the method of delivery from the shop
          if(shippings.value) {
            for(var shipping of shippings.value) {
              if(shipping.code == deliveryCode) {
                methodsOfDeliveryForm.value.shipping = deliveryCode;
              }
            }
          }
          if(pickups.value) {
            for(var pickup of pickups.value) {
              if(pickup.code == deliveryCode) {
                methodsOfDeliveryForm.value.pickup = deliveryCode;
              }
            }
          } 
        }
      }
    }

    watch(
      product,
      (val:any, oldVal:any) => {
        // We update the form with new product
        updateProductAndExtraFormForUpdate(product.value);
      },
      { deep: true }
    )

    watch(
      () => productForm.methodsOfDeliveryCodes,
      (val:any, oldVal:any) => {
        // We update the form with new product
        updateMethodsOfDeliveryFormForUpdate();
      },
      { deep: true }
    )

    watch(
      shippings,
      (val:any, oldVal:any) => {
        // We update the form with new product
        updateMethodsOfDeliveryFormForUpdate();
      },
      { deep: true }
    )

    watch(
      pickups,
      (val:any, oldVal:any) => {
        // We update the form with new product
        updateMethodsOfDeliveryFormForUpdate();
      },
      { deep: true }
    )



    watch(
      productPictures,
      (val:any, oldVal:any) => {
        // We update the current product with new list of pictures
        onPicturesUpdated(val);
      },
      { deep: true }
    )

    return {
      mode,
      labelKey,
      labelsKey,
      allowCreateFrom,
      createFromForm,
      createFromProduct,
      productInput,
      isCategoryListLoading,
      categories,
      isProductListLoading,
      products,
      availableLanguageCodes,
      getLocalizedContentOptions,
      getListPicturesOptions,
      getProductTitle,
      pickups, 
      shippings,
      methodsOfDeliveryForm,
      wineForm,
      activityForm,
      productForm,
      productPictures,
      buttonLabel,
      onFormSubmit,
      allowedProductTypes
    }
  }
})
</script>