<template>
  <div class="activity-form">
    <div class="row">
      <div class="col-lg-12">
        <h3>{{$t('shop.info.activity.information')}}</h3>
      </div>
    </div>
    <TextField 
      v-bind:value.sync="form.duration" 
      fieldType="number"
      id="updateDuration" 
      :label="$t('shop.info.activity.duration')" 
      :placeholder="$t('shop.info.activity.duration_placeholder')" 
      :labelAsColumn="true"
      :required="true"
    />
    <TextField 
      v-bind:value.sync="form.minNumberOfPersons" 
      fieldType="number"
      id="updateMinNumberOfPersons" 
      :label="$t('shop.info.activity.minNumberOfPersons')" 
      :placeholder="$t('shop.info.activity.minNumberOfPersons_placeholder')" 
      :labelAsColumn="true"
      :required="false"
    />
    <TextField 
      v-bind:value.sync="form.maxNumberOfPersons" 
      fieldType="number"
      id="updateMaxNumberOfPersons" 
      :label="$t('shop.info.activity.maxNumberOfPersons')" 
      :placeholder="$t('shop.info.activity.maxNumberOfPersons_placeholder')" 
      :labelAsColumn="true"
      :required="false"
    />
    <div v-if="(availabilities != null && availabilities.length > 0)" >
        <div class="hr-line-dashed"></div>
        <div class="row">
          <div class="col-lg-12">
            <h3>{{$t('shop.info.activity.availabilities.title')}}</h3>
          </div>
        </div>
        <Select 
          v-if="availabilities.length > 0"
          :selectOptions="{
            options: availabilities,
            getOptionLabel : option => getLocalizedText(option.title),
            reduce: option => option._id,
            multiple: true
          }"
          v-bind:value.sync="form.availabilities" 
          id="availabilities" 
          :label="$t('shop.info.activity.availabilities.label')" 
          :placeholder="$t('shop.info.activity.availabilities.placeholder')" 
          labelAsColumn 
        />
      </div>
  </div>
</template>

<style>
  
</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';

import TextField from '@fwk-client/components/panels/input/TextField.vue';
import Select from '@fwk-client/components/panels/input/Select.vue';

import { useAvailabilityAdmin } from '../../../../composables/useAvailabilityAdmin';

export default defineComponent({
  props: {
    product: {
      type: Object as PropType<any>,
      required: false
    }
  },
  components: {
    TextField,
    Select
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { 
      isListLoading,
      availabilities
    } = useAvailabilityAdmin({}, context);

    const availableLanguageCodes = ['fr','en'];

    const form:Ref<any> = ref({
      // INFORMATION
      duration : 60,
      minNumberOfPersons : undefined,
      maxNumberOfPersons : undefined,
      // AVAILABILITIES
      availabilities : []
    });
    const { product } = toRefs(props);

    const updateFormFromActivity = (product:any) => {
      form.value = {
        // INFORMATION
        duration : product.duration,
        minNumberOfPersons : product.minNumberOfPersons,
        maxNumberOfPersons : product.maxNumberOfPersons,
        // AVAILABILITIES
        availabilities : product.availabilities_ids
      };
    }
    // We populate the form based on the product
    if(props.product) {
      updateFormFromActivity(props.product);
    }

    const updateFormToStore = (form:any) => {
      // We check the duration
      if(form.duration == "") {
        form.duration = undefined;
      }

      // We check the minNumberOfPersons
      if(form.minNumberOfPersons == "") {
        form.minNumberOfPersons = undefined;
      }

      // We check the maxNumberOfPersons
      if(form.maxNumberOfPersons == "") {
        form.maxNumberOfPersons = undefined;
      }

      return form;
    }

    watch(
      form,
      (val:any, oldVal:any) => {
        var outputForm = updateFormToStore({...val})
        context.emit('update:value', outputForm);
      },
      { deep: true }
    )

    watch(
      product,
      (val:any, oldVal:any) => {
        updateFormFromActivity(val);
      },
      { deep: true }
    )

    return {
      form,
      availabilities
    }
  }  
})
</script>