<template>
  
    <form role="form" @submit="onFormSubmit" name="update-category">
      <div class="form-group">
        <label for="updateTitle" class="sr-only">{{$t('shop.info.category.title')}}</label>
        <input id="updateTitle" type="text" :placeholder="$t('shop.info.category.title_placeholder')" class="form-control" v-model="categoryForm.title" required />
      </div>
      <button class="btn btn-primary ladda-button update" data-style="zoom-in" type="submit">{{$t('shop.update.button')}}</button>
    </form>

</template>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import * as Ladda from 'ladda';

import { useCategoryAdmin } from '../../../../composables/useCategoryAdmin';

export default defineComponent({
  props: {
      mode: {
        type: String as PropType<"PRODUCT"|"ACTIVITY">,
        default: "PRODUCT"
      },
      category: {
        type: Object,
        required: true
      }
  },
  components: {
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { categoryForm, updateCategoryFormForUpdate, updateCategory } = useCategoryAdmin(props, context);

    const { category } = toRefs(props);

    var laddaSubmit:Ladda.LaddaButton|null = null;

    updateCategoryFormForUpdate(category.value);

    onMounted(() => {
      var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-category] button.update' );
      laddaSubmit = Ladda.create(submitButton!);
    })

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();

      updateCategory().then((result:any) => {
        if(result.updated) {
          context.emit('category-updated', result.category);
        }
        laddaSubmit!.stop();
      })        
    }

    return {
      categoryForm,
      onFormSubmit
    }
  }
})
</script>